<script>
import i18n from "@/i18n";
import {managerStore} from "@/store/managerStore";
import Dialog from "primevue/dialog";
import {mapActions, mapState} from "pinia";
import {baseLoaderStore} from "@/store/baseLoaderStore";
export default {
  name: "ManagerChangeStatusConfirmModal",
  props: {
    modalStateParam: {
      type: Boolean
    },
    managerData: {
      type: Object
    }
  },
  components: {
    Dialog
  },
  computed: {
    ...mapState(managerStore, [
      'changeStatusConfirmModalState',
    ]),
    modalState: {
      get() {
        return this.modalStateParam;
      },
      set() {
        this.closeModal();
      },
    },
    modalText() {
      return this.managerData.status === 'ACTIVE'
          ? i18n.global.t('managerView.manager.changeStatusModal.deactivateConfirmText')
          : i18n.global.t('managerView.manager.changeStatusModal.activateConfirmText');
    }
  },
  methods : {
    ...mapActions(baseLoaderStore, [
      'showBaseLoader',
      'hideBaseLoader'
    ]),
    ...mapActions(managerStore, [
      'toggleChangeStatusConfirmModalState',
      'toggleManagerStatusAction'
    ]),
    closeModal() {
      this.$emit('on-close');
    },
    toggleManagerStatus() {
      const id = this.managerData.id;
      const status = this.managerData.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';

      this.showBaseLoader();
      this.toggleManagerStatusAction(id, status).then(()=> {
        this.$emit('on-change-status');
      });
      this.closeModal();
    },
  }
}
</script>

<template>
<Dialog v-model:visible="modalState"
        class="manager-change-status-modal"
        modal
        :header="$t('managerView.manager.changeStatusModal.title')"
>
  <div>
    <span>{{modalText}}</span>
  </div>
  <template #footer>
    <button type="button" class="p-dialog-close-btn" @click="closeModal">
      {{ $t('common.buttons.cancel') }}
    </button>
    <button class="p-dialog-primary-btn"
            @click="toggleManagerStatus"
    >
      {{$t('common.buttons.ok')}}
    </button>
  </template>
</Dialog>
</template>

<style lang="scss">
.manager-change-status-modal {
  width: 400px;
  .p-dialog-content {
    padding: 20px;
  }
}
</style>