import {CommitteeType, ProposalOfficialStatus} from "@/store/model/ExtraProposalAttributes";
import i18n from "@/i18n";

export function committeeName(committeeType) {
    if (committeeType === CommitteeType.LABOR_MARKET) {
        return i18n.global.t('proposal.external.riksdagen.committeeName.laborMarket');
    } else if (committeeType === CommitteeType.CIVIL_AFFAIRS) {
        return i18n.global.t('proposal.external.riksdagen.committeeName.civilAffairs');
    } else if (committeeType === CommitteeType.FINANCE) {
        return i18n.global.t('proposal.external.riksdagen.committeeName.finance');
    } else if (committeeType === CommitteeType.DEFENCE) {
        return i18n.global.t('proposal.external.riksdagen.committeeName.defence');
    } else if (committeeType === CommitteeType.JUSTICE) {
        return i18n.global.t('proposal.external.riksdagen.committeeName.justice');
    } else if (committeeType === CommitteeType.CONSTITUTION) {
        return i18n.global.t('proposal.external.riksdagen.committeeName.constitution');
    } else if (committeeType === CommitteeType.CULTURE) {
        return i18n.global.t('proposal.external.riksdagen.committeeName.culture');
    } else if (committeeType === CommitteeType.ENVIRONMENT) {
        return i18n.global.t('proposal.external.riksdagen.committeeName.environment');
    } else if (committeeType === CommitteeType.INDUSTRY) {
        return i18n.global.t('proposal.external.riksdagen.committeeName.industry');
    } else if (committeeType === CommitteeType.TAXES) {
        return i18n.global.t('proposal.external.riksdagen.committeeName.taxes');
    } else if (committeeType === CommitteeType.INSURANCE) {
        return i18n.global.t('proposal.external.riksdagen.committeeName.insurance');
    } else if (committeeType === CommitteeType.HEALTH) {
        return i18n.global.t('proposal.external.riksdagen.committeeName.health');
    } else if (committeeType === CommitteeType.COMMUNICATIONS) {
        return i18n.global.t('proposal.external.riksdagen.committeeName.communications');
    } else if (committeeType === CommitteeType.EDUCATION) {
        return i18n.global.t('proposal.external.riksdagen.committeeName.education');
    } else if (committeeType === CommitteeType.FOREIGN_AFFAIRS) {
        return i18n.global.t('proposal.external.riksdagen.committeeName.foreignAffairs');
    } else if (committeeType === CommitteeType.DEFENSE) {
        return i18n.global.t('proposal.external.riksdagen.committeeName.defense');
    }
    return '';
}

export function proposalStatusText(proposalStatus, proposalForumName, committeeType) {
    const committeeNameText = committeeName(committeeType);

    if (proposalStatus === ProposalOfficialStatus.DRAFT) {
        return i18n.global.t('proposal.generalInfo.proposalStatus.draft');
    } else if (proposalStatus === ProposalOfficialStatus.TO_BE_RAISED) {
        return `${i18n.global.t('proposal.generalInfo.proposalStatus.toBeRaisedTo')} ${proposalForumName}`;
    } else if (proposalStatus === ProposalOfficialStatus.REVOKED) {
        return i18n.global.t('proposal.generalInfo.proposalStatus.archivedDueToRevoked');
    } else if (proposalStatus === ProposalOfficialStatus.NOT_APPROVED) {
        return `${i18n.global.t('proposal.generalInfo.proposalStatus.archivedDueToNotApproved')} ${proposalForumName}`;
    } else if (proposalStatus === ProposalOfficialStatus.EXPIRED) {
        return i18n.global.t('proposal.generalInfo.proposalStatus.archivedDueToExpired');
    } else if (proposalStatus === ProposalOfficialStatus.ARCHIVED) {
        return i18n.global.t('proposal.generalInfo.proposalStatus.archived');
    } else if (proposalStatus === ProposalOfficialStatus.REGISTERED) {
        return `${i18n.global.t('proposal.generalInfo.proposalStatus.registered')} ${proposalForumName}`;
    } else if (proposalStatus === ProposalOfficialStatus.APPROVED) {
        return `${i18n.global.t('proposal.generalInfo.proposalStatus.approved')} ${proposalForumName}`;
    } else if (proposalStatus === ProposalOfficialStatus.REFERRED || proposalStatus === ProposalOfficialStatus.TO_BE_DECIDED || proposalStatus === ProposalOfficialStatus.IMPLEMENTED || proposalStatus === ProposalOfficialStatus.REJECTED) {
        return `${i18n.global.t('proposal.generalInfo.proposalStatus.referredTo')} ${committeeNameText}`;
    }
    return '';
}