
export class CreatorType {

    static USER = "USER";
    static ORGANIZATION = "ORGANIZATION";
    static FORUM = "FORUM";
}

export class ImgLabel {

    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.imgHash = data.imgHash;
        this.type = data.type; // CreatorType
    }
}