import {formatZonedDateTime} from "@/baseUtils";
import {Attachment} from "@/store/model/Attachment";
import {CreatorType, ImgLabel} from "@/store/model/ImgLabel";
import {Content} from "@/store/model/Content";
import {EntityVersion} from "@/store/model/EntityVersion";
import {BaseExtraProposalAttributes, RiksdagenExtraProposalAttributes} from "@/store/model/ExtraProposalAttributes";
import {ImportanceType} from "@/store/model/ImportanceType";

export class ProposalType {

    static PROPOSAL = "PROPOSAL";
    static RIKSDAGEN_PROPOSAL = "RIKSDAGEN_PROPOSAL";
}

export class Proposal {

    constructor(data) {
        this.id = data.id;
        this.version = data.version;
        this.title = data.title;
        this.issueNumber = data.issueNumber;
        this.preview = data.preview ? new Attachment(data.preview) : null;
        this.forum = new ImgLabel(data.forum);
        this.type = data.type; // ProposalType
        this.changeType = data.changeType; // EntityChangeType
        this.status = data.status; // VersionedEntityStatus
        this.importanceType = data.importanceType; // ImportanceType
        this.proposer = new ImgLabel(data.proposer);
        this.editor = new ImgLabel(data.editor);
        this.actualProposal = data.actualProposal ? new Content(data.actualProposal) : null;
        this.subjects = data.subjects; // Array<string>
        this.versions = data.versions.map(v => new EntityVersion(v));
        this.extraAttributes = data.type === ProposalType.PROPOSAL ? new BaseExtraProposalAttributes(data.extraAttributes) : new RiksdagenExtraProposalAttributes(data.extraAttributes);
        this.createDateTime = formatZonedDateTime(data.createDateTime);
    }

    getImportanceTypeOr() {
        return this.importanceType ? this.importanceType : ImportanceType.MATTER_OF_THE_HEART;
    }

    getCreatorTypeOr() {
        return this.proposer ? this.proposer.type : CreatorType.USER;
    }

    getProposerIdOr() {
        return this.proposer ? this.proposer.id : null;
    }
}