<script>
import Dialog from "primevue/dialog";
import {mapActions, mapState} from "pinia";
import {proposalStore} from "@/store/proposalStore";

export default {
  name: "ProposalNeedUpdateModal",
  components: {
    Dialog
  },
  computed: {
    ...mapState(proposalStore, [
      'proposalNeedUpdateModalState'
    ]),
    modalState: {
      get() {
        return this.proposalNeedUpdateModalState;
      },
      set() {
        this.toggleProposalNeedUpdateModalState();
      },
    },
  },
  methods: {
    ...mapActions(proposalStore, [
      "toggleProposalNeedUpdateModalState"
    ]),
    closeModal() {
      this.toggleProposalNeedUpdateModalState();
    }
  }
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          :header="$t('proposalNeedUpdateModal.title')"
          class="proposal-need-update-modal"
          modal
  >
    <div>
      <p>{{ $t('proposalNeedUpdateModal.p1') }}</p>
      <p>{{ $t('proposalNeedUpdateModal.p2') }}</p>
    </div>
    <template #footer>
      <button type="button" class="p-dialog-close-btn" @click="closeModal()">
        {{$t('common.buttons.close')}}
      </button>
    </template>
  </Dialog>
</template>

<style lang="scss">
.proposal-need-update-modal {
  .p-dialog-content {
    padding: 15px;
    p {
      margin-bottom: 5px;
    }
  }
}
</style>