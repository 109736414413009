
export class AttachmentName {

    static AVATAR = "AVATAR";
    static HERO_IMG = "HERO_IMG";
    static USER_TERMS = "USER_TERMS";
    static VIDEO = "VIDEO";
    static VIDEO_PREVIEW = "VIDEO_PREVIEW";
    static AUDIO = "AUDIO";
    static PROPOSAL_VIDEO = "PROPOSAL_VIDEO";
    static OPINION_VIDEO = "OPINION_VIDEO";
    static PROPOSAL_VIDEO_PREVIEW = "PROPOSAL_VIDEO_PREVIEW";
    static OPINION_VIDEO_PREVIEW = "OPINION_VIDEO_PREVIEW";
    static PROPOSAL_OPINION_PREVIEW = "PROPOSAL_OPINION_PREVIEW";
    static NEWS_IMG = "NEWS_IMG";
    static AD_IMAGE = "AD_IMAGE";
    static AD_VIDEO = "AD_VIDEO";
    static AD_VIDEO_PREVIEW = "AD_VIDEO_PREVIEW";
    static AD_INVOICE = "AD_INVOICE";
    static PDF_FILE = "PDF_FILE";
    static XML_FILE = "XML_FILE";
    static TEXT_FILE = "TEXT_FILE";
    static DOC_FILE = "DOC_FILE";
    static VIDEO_FILE = "VIDEO_FILE";
    static AUDIO_FILE = "AUDIO_FILE";
    static HTML_FILE = "HTML_FILE";
    static PDF_DIFF_FILE = "PDF_DIFF_FILE";
    static ZIP_FILE = "ZIP_FILE";
    static ANY_FILE = "ANY_FILE";
}

export class AttachmentType {

    static ANY = "ANY";
    static IMAGE = "IMAGE";
    static DOCUMENT = "DOCUMENT";
    static VIDEO = "VIDEO";
    static AUDIO = "AUDIO";
}

export class Attachment {

    constructor(data) {
        this.id = data.id;
        this.hash = data.hash;
        this.name = data.name; // AttachmentName
        this.type = data.type; // AttachmentType
    }
}