<script>
import axios from "axios";
import {mapActions, mapState} from "pinia";
import {baseLoaderStore} from "@/store/baseLoaderStore";
import {managerStore} from "@/store/managerStore";
import {authStore} from "@/store/authStore";
import Dialog from "primevue/dialog";
import BaseHeader from "@/components/BaseHeader.vue";
import ManagerTasksOverview from "@/views/manager/ManagerTasksOverview/ManagerTasksOverview.vue";
import BasisForInvoiceSection from "@/views/manager/ManagerBasisForInvoice/BasisForInvoiceSection.vue";
import ManagerCompanyInfoSection from "@/views/manager/ManagerCompanyInfoSection.vue";


export default {
  name: "ManageLoungeView",
  components: {
    Dialog,
    BaseHeader,
    ManagerTasksOverview,
    BasisForInvoiceSection,
    ManagerCompanyInfoSection
  },
  data() {
    return {
      welcomeVideoModalState: false,
      introVideoInfoModalState: false,
      taskStatistics: null,
      taskCounts: null,
    }
  },
  computed: {
    ...mapState(authStore, [
      'managerProfileInfo',
    ]),
  },
  watch: {
    welcomeVideoModalState: {
      handler(val) {
        if (!val) {
          this.hideIntroVideo();
        }
      }
    }
  },
  methods: {
    ...mapActions(baseLoaderStore, [
      'showBaseLoader',
      'hideBaseLoader'
    ]),
    ...mapActions(managerStore, [
      'getManagerStatisticAction',
      'getManagerTaskCounts'
    ]),
    checkWelcomeVideoState() {
      const state = localStorage.getItem("managerLoungeWelcomeVideoState") === 'true';

      if (!state) {
        this.welcomeVideoModalState = true;
        localStorage.setItem("managerLoungeWelcomeVideoState", "true");
      }
    },
    hideIntroVideo() {
      localStorage.setItem("managerLoungeWelcomeVideoState", "true");
      this.welcomeVideoModalState = false;
      this.introVideoInfoModalState = true;
    },
    openLinkToIntroVideo() {
      window.open('/managerKnowledgeArea?jobType=KA_GENERAL_INSTRUCTIONS', '_blank').focus();
      this.introVideoInfoModalState = false;
    }
  },
  beforeMount() {
    this.showBaseLoader();
    axios.all([
      this.getManagerStatisticAction().then((resp)=> {
        this.taskStatistics = resp.data;
      }),
      this.getManagerTaskCounts().then((resp) => {
        this.taskCounts = resp.data.counts;
      })
    ]).then(axios.spread(() => {
      this.hideBaseLoader();
      this.checkWelcomeVideoState();
    })).catch((error) => {
      console.log(error);
      this.hideBaseLoader();
    });
  },
  mounted() {
    window.parent.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.hideIntroVideo();
      }
    });
  }
}
</script>

<template>
  <base-header/>
  <div class="manager-lounge-view">
    <manager-tasks-overview :tasks-statistics="taskStatistics"
                            :tasks-counts="taskCounts"
    />
    <basis-for-invoice-section v-if="managerProfileInfo"/>
    <manager-company-info-section v-if="managerProfileInfo"/>

    <!-- Welcome video modal -->
    <Dialog v-model:visible="welcomeVideoModalState"
            class="intro-video-modal"
            :header="$t('becomeManager.lifeAtPeoplePolitics')"
            modal
    >
      <div>
        <video id="introVideo" class="vjs-tech intro-video" width="800px" controls preload="auto" autoplay="true"
               tabindex="-1"
               role="application"
               src="https://d3lhb68qeo1nf6.cloudfront.net/video/people_politics_video_720.mp4"
               data-setup="{}">
          <source src="https://d3lhb68qeo1nf6.cloudfront.net/video/people_politics_video_720.mp4" type="video/mp4">
          <param name="allowfullscreen" value="true">
        </video>
      </div>
      <template #footer>
        <button type="button" class="p-dialog-close-btn" @click="hideIntroVideo()">
          {{$t('common.buttons.close')}}
        </button>
      </template>
    </Dialog>

    <!-- Info about intro video modal -->
    <Dialog v-model:visible="introVideoInfoModalState"
            :header="'Info'"
            class="intro-info-modal"
            modal
    >
      <div>
        <p>
          {{$t('managerLounge.videInstructionsInfo.p1')}}
          <span class="text-link font-weight-bold" @click="openLinkToIntroVideo">
            {{$t('managerLounge.videInstructionsInfo.p2')}}
          </span>
        </p>
      </div>
      <template #footer>
        <button type="button" class="p-dialog-close-btn" @click="introVideoInfoModalState = false">
          {{$t('common.buttons.close')}}
        </button>
      </template>
    </Dialog>
  </div>
</template>

<style lang="scss">
.manager-lounge-view {
  width: 100%;
  padding: 79px 0 50px;
}

.invoice-section,
.tasks-overview-section,
.company-info-section {
  width: 970px;
  min-width: 970px;
  margin: 0 auto 10px;
  padding: 15px 15px 25px;
  background: #fff;
  box-sizing: border-box;

  &__title {
    display: block;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
  }
}

.intro-info-modal {
  .p-dialog-content {
    padding: 20px;
  }
}

</style>