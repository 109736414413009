<script>
import {mapActions, mapState} from "pinia";
import {baseLoaderStore} from "@/store/baseLoaderStore";
import {authStore} from "@/store/authStore";
import {maintenanceStore} from "@/store/maintenanceStore";
import {contentReviewStore} from "@/store/contentReviewStore";
import CrProposersListModal from "@/components/Modals/CrProposersListModal.vue";


export default {
  name: "ManagerContentReviews",
  props: {
    userId: {
      type: Number
    },
    urlParams: {
      type: String
    }
  },
  components: {
    CrProposersListModal
  },
  computed: {
    ...mapState(authStore, [
        'userIsActiveManager'
    ]),
    ...mapState(contentReviewStore,[
      'crItemProposers'
    ]),
    ...mapState(maintenanceStore, [
      'isResolveContentReviewBlocked'
    ]),
    frameUrl() {
      let url =  new URL(window.location.origin + `/${this.userId}/mydesktop?tab=content-review`);

      if (this.urlParams) {
        let params = this.urlParams;
        params.append('page', '1')
        params.append('tab', 'content-review')
        url.search = params;
      }

      return url.pathname + url.search + '&_f=true';
    }
  },
  methods: {
    ...mapActions(baseLoaderStore, [
      'hideBaseLoader'
    ]),
    ...mapActions(maintenanceStore, [
      'getResolveContentReviewInfo',
      'initEmptyResolveContentReviewInfo'
    ]),
  },
  mounted() {
    const $managerViewFrame = document.getElementById('managerViewFrame');

    $managerViewFrame.onload = () => {
      this.hideBaseLoader();
      $managerViewFrame.contentWindow.window.app.disableResolveContentReviews = this.isResolveContentReviewBlocked;
    };

    if (this.userIsActiveManager) {
      this.getResolveContentReviewInfo();
    } else {
      this.initEmptyResolveContentReviewInfo();
    }
  }
}
</script>

<template>
  <div>
    <iframe :src="frameUrl"
            sandbox="allow-scripts allow-forms allow-popups allow-downloads allow-same-origin allow-top-navigation allow-popups-to-escape-sandbox"
            frameborder="0"
            id="managerViewFrame"
            class="manager-view-frame"
    ></iframe>
  </div>
  <cr-proposers-list-modal :proposers-list="crItemProposers"/>
</template>