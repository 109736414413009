import {EntityChangeType} from "@/store/model/EntityChangeType";

export class ProposalUpdate {

        constructor(title, importanceType, changeType, subjects) {

            this.title = title;
            this.importanceType = importanceType; // ImportanceType
            this.changeType = changeType ? changeType : EntityChangeType.MAJOR; // EntityChangeType
            this.subjects = subjects; // Array<Label>
        }
}