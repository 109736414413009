<script>
import i18n from "@/i18n";
import {mapActions} from "pinia";
import {helpers, minLength, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {proposalStore} from "@/store/proposalStore";
import {documentStore} from "@/store/documentStore";
import Editor from '@tinymce/tinymce-vue'
import ProgressBar from 'primevue/progressbar';
import Dialog from "primevue/dialog";
import {CmsDocumentContentStatus, CmsDocumentContentType} from "@/store/model/Content";
import {AttachmentName} from "@/store/model/Attachment";

export default {
  name: "ArgumentNewItem",
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    Editor,
    ProgressBar,
    Dialog
  },
  props: {
    argIndex: {
      type: Number
    },
    argData: {
      type: Object
    },
    canBeRemoved: {
      type: Boolean
    }
  },
  validations () {
    return this.validationsFields;
  },
  data() {
    return {
      editorApiKey: 'iznusnmu0tjvdqfe8mexao6gp303nf3pa3l1mmj0vxbogx7n',
      editorConfig: {
        plugins: 'lists link image table code help wordcount',
        height : "224",
        width: "615",
        placeholder: i18n.global.t('argument.create.enterYourArgument'),
        menubar: false,
        resize: true,
        branding: false,
        elementpath: false,
        statusbar: true,
        toolbar: 'undo redo | styleselect | bold italic |',
        style_formats: [
          {
            title: "Headers",
            items: [
              {title: "Header 1", format: "h1"},
              {title: "Header 2", format: "h2"}
            ]
          }
        ],
        content_style: `
            body {
              margin: 5px 15px;
              background: #fff;
              font-size: 16px;
              font-weight: 600;
              color: #4f4f4f;
              p {margin: 0;}
            }
        …`
      },
      currentArgData: null,
      argTextMode: false,
      textArgData: "",
      isLoadingContentFromStore: false,

      // Video argument
      maxVideoDuration: 90,
      videoArgumentViewStep: 1,
      uploaderProgressBarValue: 0,
      selectedVideoFile: null,
      attachedVideoDocument: null,
      allowedVideoType: [
          'video/mp4',// mp4
          'video/quicktime',//mov
          'video/webm',//webm
          'audio/ogg',//ogg
          'video/ogg',//ogv
      ],

      removeArgModalState: false,
      videoDurationErrorModalState: false,
      notSupportedVideoFormatErrorModalState: false,
    }
  },
  watch: {
    textArgData: {
      handler(val) {
        if (val) {
          this.currentArgData.argText = this.textArgData;
          if (this.isLoadingContentFromStore) {
            this.isLoadingContentFromStore = false;
          } else {
            this.$emit('on-update', this.argIndex, this.currentArgData);
          }
        }
      }
    },
    videoArgumentViewStep: {
      handler(val) {
        if (val === 2) {
          this.uploadVideo();
        } else {
          this.endProgress()
        }
      }
    }
  },
  computed:{
    argCurrentTitle() {
      return this.currentArgData.title;
    },
    argPositionNumber() {
      let digits = String(+this.argIndex + 1).split(""),
          key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
            "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
            "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
          roman_num = "",
          i = 3;
      while (i--) {
        roman_num = (key[+digits.pop() + (i * 10)] || "") + roman_num;
      }
      return Array(+digits.join("") + 1).join("M") + roman_num;
    },
    isVideoProcessingFailed() {
      return this.attachedVideoDocument && this.attachedVideoDocument.status === CmsDocumentContentStatus.FAILED;
    },
    videoArgPreview() {
      if (this.attachedVideoDocument && this.attachedVideoDocument.status === CmsDocumentContentStatus.PROCESSING) {
        return '/html/images/processing-video-animated.svg';
      }

      let preview = this.attachedVideoDocument ? this.attachedVideoDocument.attachments.filter((item) => {
        return item.name === AttachmentName.VIDEO_PREVIEW;
      })[0] : null;

      return preview
          ? '/api/video/' + preview.hash + '/preview'
          : '/html/images/arguments/private-video.svg';
    },
    validationsFields() {
      const allValid = () => true;
      let fields = {
        argCurrentTitle: {
          minLength: minLength(5),
          required: required,
        },
        selectedVideoFile: {
          allValid
        },
        textArgData: {
          allValid
        }
      };

      if (this.argData.type === 'VIDEO') {
        fields.selectedVideoFile = {
          successful: helpers.withMessage(
              'argument.create.videoCantBeSavedAsArgument',
              () => this.attachedVideoDocument && this.attachedVideoDocument.status !== CmsDocumentContentStatus.PROCESSING && this.attachedVideoDocument.status !== CmsDocumentContentStatus.FAILED
          )
        };
      } else if (this.argData.type === 'TEXT') {
        fields.textArgData = {
          required: required
        }
      }

      return fields;
    }
  },
  methods: {
    ...mapActions(proposalStore, [
        'setAsModified'
    ]),
    ...mapActions(documentStore, [
      'getTextDocumentContent',
      'createAttachmentDocument',
      'getDocumentContent'
    ]),
    changeArgType(type) {
      this.textArgData = '';
      this.currentArgData.type = type;
      this.v$.$reset();
      this.$emit('on-update', this.argIndex, this.currentArgData);
    },
    getArgumentContent() {
      const contentType = this.currentArgData.argumentDetailed.type;
      const contentId = this.currentArgData.argumentDetailed.contentId;
      if (contentType === CmsDocumentContentType.HTML_TEXT) {
        this.currentArgData.type = 'TEXT';
        this.getTextDocumentContent(contentId).then((documentText) => {
          this.textArgData = documentText.data;
        });
      } else if (contentType === CmsDocumentContentType.VIDEO) {
        this.currentArgData.type = 'VIDEO';
        this.videoArgumentViewStep = 3;

        this.getDocumentContent(contentId).then((resp) => {
          this.attachedVideoDocument = resp.data;
        });
      }
    },
    selectFile(event) {
      this.checkVideoDuration(event.target.files, (duration)=> {
        const fileType = event.target.files[0].type;

        if (!this.allowedVideoType.includes(fileType)) {
          this.toggleNotSupportedVideoModalState();
          this.selectedVideoFile = null;
        } else if (duration > this.maxVideoDuration) {
          this.toggleVideoDurationErrorModalState();
          this.selectedVideoFile = null;
        } else {
          this.selectedVideoFile = event.target.files;
          this.videoArgumentViewStep = 2;
        }
      });
    },
    checkVideoDuration(files, callback) {
      if (files && files.length) { // for accepting only MP4 video
        const videoElmnt = document.createElement('video');

        videoElmnt.src = URL.createObjectURL(files[0]);
        videoElmnt.load();
        videoElmnt.addEventListener('loadedmetadata', () => {
          const duration = videoElmnt.duration;

          URL.revokeObjectURL(videoElmnt.src);
          videoElmnt.remove();

          callback(duration);
        });
      } else {
        return false;
      }
    },
    uploadVideo() {
      this.startProgress();
      const contentNumber = this.argData.argumentDetailed ? this.argData.argumentDetailed.contentNumber : null
      const status = this.argData.argumentDetailed ? this.argData.argumentDetailed.status : null;
      if (contentNumber && status === CmsDocumentContentStatus.DRAFT) {
        const contentId = this.argData.argumentDetailed ? this.argData.argumentDetailed.contentId : null;
        const attachmentId = this.argData.argumentDetailed.attachments.find(a => a.name === AttachmentName.VIDEO).id;
        return this.updateAttachmentDocument(contentId, attachmentId, this.selectedVideoFile)
            .then((resp) => {
              this.endProgress();
              this.videoArgumentViewStep = 3;
              this.currentArgData.attachedVideoDoc = resp.data;
              this.$emit('on-update', this.argIndex, this.currentArgData);
              this.getVideoDocumentContent();
            });
      } else {
        this.createAttachmentDocument(this.selectedVideoFile, CmsDocumentContentType.VIDEO, this.argCurrentTitle, contentNumber)
            .then((resp) => {
              this.endProgress();
              this.videoArgumentViewStep = 3;
              this.currentArgData.attachedVideoDoc = resp.data;
              this.$emit('on-update', this.argIndex, this.currentArgData);
              this.getVideoDocumentContent();
            });
      }
    },
    startProgress() {
      this.interval = setInterval(() => {
        let newValue = this.uploaderProgressBarValue + Math.floor(Math.random() * 10) + 1;
        if (newValue >= 100) {
          newValue = 100;
        }
        this.uploaderProgressBarValue = newValue;
      }, 2000);
    },
    endProgress() {
      clearInterval(this.interval);
      this.interval = null;
    },
    getVideoDocumentContent() {
      this.checkVideoContentInterval = setInterval(() => {
        this.getDocumentContent(this.currentArgData.attachedVideoDoc.id).then((docResp) => {
          this.attachedVideoDocument = docResp.data;
          if (docResp.data && docResp.data.status !== CmsDocumentContentStatus.PROCESSING) {
            this.stopLoadingVideoDocumentContent();
          }
          if (docResp.data && docResp.data.status === CmsDocumentContentStatus.FAILED) {
            this.videoArgumentViewStep = 1;
          }
        });
      }, 5000)
    },
    stopLoadingVideoDocumentContent() {
      if (this.checkVideoContentInterval) {
        clearInterval(this.checkVideoContentInterval);
        this.checkVideoContentInterval = null;
      }
    },
    addArgumentSource() {
      alert('To be implemented');
    },
    changeArgTitle() {
      this.onChange('argTitle');
      this.$emit('on-update', this.argIndex, this.currentArgData);
      this.v$.argCurrentTitle.$reset();
    },
    onChange(type) {
      this.currentArgData.changedTitle = type === 'argTitle';
      this.currentArgData.changedContent = type === 'argContent';
    },
    validateArg() {
      this.v$.$touch();
    },
    removeArgument() {
      this.$emit('on-remove', this.argIndex);
      this.toggleRemoveArgModal();
      this.setAsModified('proposalArgumentsChanged');
    },
    toggleRemoveArgModal() {
      this.removeArgModalState = !this.removeArgModalState;
    },
    toggleNotSupportedVideoModalState() {
      this.notSupportedVideoFormatErrorModalState = !this.notSupportedVideoFormatErrorModalState;
    },
    toggleVideoDurationErrorModalState() {
      this.videoDurationErrorModalState = !this.videoDurationErrorModalState;
    },
    processRemoveArg() {
      if (this.currentArgData.title.length > 1 || this.textArgData.length > 0) {
        this.toggleRemoveArgModal();
      } else {
        this.removeArgument();
      }
    }
  },
  beforeMount() {
    this.currentArgData = this.argData;
    if (this.argData.argumentDetailed) {
      this.isLoadingContentFromStore = true;
      this.getArgumentContent();
    }

    if (!this.currentArgData.argumentDetailed) {
      this.currentArgData.type = CmsDocumentContentType.VIDEO;
    }
  },
  mounted() {
    this.currentArgData = this.argData;
  },
}
</script>

<template>
<div class="argument-item">
  <div class="argument-item__main">
    <div class="argument-item__header"
         :class="{'error': v$.argCurrentTitle.$error}"
    >
      <span class="argument-position-number">{{argPositionNumber}}.</span>
      <input type="text"
             v-model="currentArgData.title"
             maxlength="140"
             :placeholder="$t('argument.create.titleOfArgument')"
             class="argument-title-input"
             @keyup="changeArgTitle()"
      />
      <font-awesome-icon v-if="canBeRemoved && !currentArgData.id"
                         :icon="['fas', 'close']"
                         class="argument-remove-control"
                         @click="processRemoveArg"
      />
    </div>
    <div class="argument-item__content">
      <!-- Video argument -->
      <div v-if="currentArgData.type === 'VIDEO'"
           class="video-argument-wrap"
           :class="{'error': v$.selectedVideoFile.$error}"
      >
        <template v-if="videoArgumentViewStep === 1">
          <div class="argument-video-uploader">
            <div v-if="isVideoProcessingFailed">
              <span class=" error-text ml_auto">Video processing is failed</span>
            </div>
            <div class="argument-video-uploader__img"></div>
            <div class="argument-video-uploader__info">
              <span class="title">{{$t('argument.create.uploadYourArgumentDescription')}}</span>
              <label for="file-upload" class="file-upload-control">
                <span class="file-upload-control__text">
                  {{$t('argument.create.uploadVideo')}}
                </span>
                <input type="file"
                       id="file-upload"
                       class=""
                       @change="selectFile"
                />
              </label>
              <span class="desc">{{$t('argument.create.videoMaxSec')}}</span>
            </div>
          </div>
          <div class="arg-type-switcher" @click="changeArgType('TEXT')">
            <font-awesome-icon :icon="['fas', 'pen-to-square']"
                               class="arg-type-switcher__icon"
            />
            <span class="arg-type-switcher__text">
              {{$t('argument.create.enterTextInstead')}}
            </span>
          </div>
        </template>
        <template v-else-if="videoArgumentViewStep === 2">
          <div class="argument-video-uploading">
            <span class="argument-video-uploading__title">
              {{$t('argument.create.pleaseWaitVideoUploaded')}}
            </span>
            <ProgressBar :value="uploaderProgressBarValue"
                         :show-value="false"
                         class="argument-video-uploading__progress-bar"
            />
          </div>
        </template>
        <template v-else-if="videoArgumentViewStep === 3">
          <div class="argument-video-uploaded">
            <div v-if="attachedVideoDocument" class="argument-video-uploaded__preview">
              <div v-if="attachedVideoDocument.status === 'PROCESSING'"
                   class="video-preview video-preview--processing"
              >
              </div>
              <div v-else class="video-preview">
                <img :src="videoArgPreview" class="video-preview video-preview__img" alt="">
              </div>
            </div>
            <div class="argument-video-uploaded__info">
              <span class="argument-video-uploaded__text">
                {{$t('argument.create.wantMakeArgumentStronger')}}
              </span>
              <button type="button" class="add-source-control" @click="addArgumentSource">
                {{$t('argument.create.addSourcesToVideo')}}
              </button>
            </div>
          </div>
        </template>
      </div>
      <div v-else-if="currentArgData.type === 'TEXT'"
           class="text-argument-wrap"
           :class="{'error': v$.textArgData.$error}"
      >
        <Editor v-model="textArgData"
                :api-key="editorApiKey"
                :init="editorConfig"
                @keyup="onChange('argContent')"
        />
        <div class="arg-type-switcher" @click="changeArgType('VIDEO')">
          <font-awesome-icon :icon="['fas', 'film']"
                             class="arg-type-switcher__icon"
          />
          <span class="arg-type-switcher__text">
            {{$t('argument.create.uploadVideoInstead')}}
          </span>
        </div>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="notSupportedVideoFormatErrorModalState"
          :header="$t('argument.create.videoUploadError')"
          class="video-error-arg-modal"
          modal
  >
    <div>
      {{$t('argument.create.notSupportedVideoFormat')}}
    </div>
  </Dialog>

  <Dialog v-model:visible="videoDurationErrorModalState"
          :header="$t('argument.create.videoUploadError')"
          class="video-error-arg-modal"
          modal
  >
    <div>
      {{$t('argument.create.theVideoIsTooLong')}}
    </div>
  </Dialog>

  <Dialog v-model:visible="removeArgModalState"
          :header="$t('argument.create.removeArg.title')"
          class="remove-arg-modal"
          modal
  >
    <div>
      {{$t('argument.create.removeArg.p1')}}
      <span class="arg-title">"{{currentArgData.title}}"</span>
      {{$t('argument.create.removeArg.p2')}}
    </div>
    <template #footer>
      <button type="button" class="p-dialog-close-btn" @click="toggleRemoveArgModal()">
        {{$t('common.buttons.cancel')}}
      </button>
      <button class="p-dialog-primary-btn" @click="removeArgument()">
        {{$t('common.buttons.delete')}}
      </button>
    </template>
  </Dialog>
</div>
</template>

<style lang="scss">
.argument-item {
  margin-bottom: 18px;
  &__main {
    width: 615px;
  }
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 25px 10px 10px;
    box-sizing: border-box;
    border-radius: 4px;
    border: solid 1px #d1d1d1;
    background-color: #ffffff;
    .argument-position-number {
      margin-right: 10px
    }
    .argument-title-input {
      height: 20px;
      line-height: 20px;
      width: 100%;
      border: none;
      font-weight: 600;
      color: #4f4f4f;
      font-size: 14px;
      appearance: none !important;
      &::placeholder {
        color: #bbbbbb;
      }
      &:focus {
        outline: none;
      }
    }
    .argument-remove-control {
      position: absolute;
      right: 10px;
      font-size: 15px;
      color: #7b7b7b;
      cursor: pointer;
    }

    &.error {
      border: 1px solid #e60907;
    }
  }
  &__content {
    width: 100%;
    box-sizing: border-box;
    border-radius: 7px;
    background-color: #f2f2f2;
    margin-bottom: 18px;
    overflow: hidden;
    .video-argument-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      min-height: 162px;
      border: 2px solid transparent;

      .argument-video-uploader {
        display: flex;
        justify-content: center;
        height: 105px;
        width: 100%;
        margin-bottom: 10px;
        padding: 20px 15px 10px;
        border-bottom: solid 1px #d1d1d1;
        &__img {
          width: 120px;
          height: 88px;
          margin-right: 20px;
          background: url("~@/assets/img/arguments/no-motivation-image.svg") no-repeat;
        }
        &__info {
          display: flex;
          flex-direction: column;
          .title {
            display: inline-block;
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: 600;
            color: #4f4f4f;
          }
          .file-upload-control {
            display: inline-block;
            height: 28px;
            max-width: 120px;
            width: fit-content;
            margin-bottom: 5px;
            font-size: 14px;
            font-weight: 600;
            line-height: 28px;
            border-radius: 3px;
            color: #FFFFFF;
            background: #7835a6;
            cursor: pointer;
            border: none;
            outline: none;
            text-align: center;
            &:hover {
              background: #672992;
            }

            input {
             opacity: 0;
            }
          }
          .desc {
            font-size: 12px;
            line-height: 1.5;
            color: #7b7b7b;
          }
        }
      }
      .argument-video-uploading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding-top: 30px;
        &__title {
          display: inline-block;
          margin-bottom: 17px;
          font-size: 14px;
          font-weight: 600;
          color: #4f4f4f;
        }
        &__progress-bar {
          display: flex;
          width: 420px;
          height: 11px;
          border-radius: 3px;
          background: #D8C6E6;
          .p-progressbar-value {
            background: #7835a6;
          }
        }
      }
      .argument-video-uploaded {
        display: flex;
        width: 100%;

        &__preview {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 162px;
          width: 250px;

          background: #D8C6E6;

          .video-preview {
            position: relative;

            &:after {
              content: '';
              display: block;
              position: absolute;
              top: 58px;
              left: 98px;
              z-index: 1;
              min-width: 50px;
              height: 50px;
              background: url("~@/assets/img/proposal/video-play-btn-75.svg") center no-repeat;
              background-size: 100%;
            }

            &__img {
              width: 250px;
              height: 162px;
            }

            &--processing {
              width: 110px;
              height: 100px;
              background: url("~@/assets/img/arguments/processing-video.svg") no-repeat;

              &:after {
                content: none;
              }
            }

          }

        }
        &__info {
          padding: 15px 20px;

          .add-source-control {
            width: 171px;
            height: 32px;
            padding: 0 15px;
            border-radius: 3px;
            font-family: 'Open Sans', Arial, sans-serif;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: -0.01px;
            color: #fff;
            border: none;
            cursor: pointer;
            background-image: linear-gradient(101deg, #881ed1, #c36aeb);
          }
        }
        &__text {
          display: inline-block;
          margin-bottom: 10px;
          max-width: 300px;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.43;
          color: #4f4f4f;
        }
      }

      .arg-type-switcher {
        display: flex;
        margin-bottom: 10px;
        cursor: pointer;
        &__icon {
          margin-right: 9px;
          font-size: 12px;
          color: #7835a6
        }
        &__text {
          font-size: 12px;
          font-weight: 600;
          color: #7835a6
        }
      }

      &.error {
        border: 2px solid #e60907;
      }
    }

    .text-argument-wrap {
      display: flex;
      justify-content: center;
      position: relative;
      border-radius: 6px;

      .tox {
        &.tox-edit-focus {
          .tox-edit-area::before {
            opacity: 0 !important;
          }
        }
        &.tox-tinymce {
          z-index: 1;
          .tox-editor-header {
            box-shadow: none;
          }
          .tox-statusbar {
            border: none;
            .tox-statusbar__help-text,
            .tox-statusbar__right-container {
              display: none;
            }
          }
        }
      }

      .arg-type-switcher {
        position: absolute;
        bottom: 10px;
        z-index: 1;
        left: 10px;
        color: #7835a6;
        cursor: pointer;

        &__icon {
          margin-right: 5px;
        }
        &__text {
          font-size: 12px;
          font-weight: 600;
        }
      }

      &.error {
        border: 2px solid #e60907;
      }
    }
  }
}

.remove-arg-modal {
  max-width: 540px;
  .p-dialog-content {
    padding: 15px;
    line-height: 16px;
    .arg-title {
      font-weight: 600;
      word-wrap: break-word;
    }
  }
}

.video-error-arg-modal {
  .p-dialog-content {
    padding: 20px 30px;
    font-size: 12px;
    max-width: 510px;
    line-height: 16px;
  }
}
</style>