<script>
import i18n from "@/i18n";
import Accordion from "primevue/accordion";
import AccordionPanel from "primevue/accordionpanel";
import AccordionHeader from "primevue/accordionheader";
import AccordionContent from "primevue/accordioncontent";

export default {
  name: "TasksOverviewList",
  props: {
    taskCounts: {
      type: Object
    }
  },
  components: {
    Accordion,
    AccordionPanel,
    AccordionHeader,
    AccordionContent
  },
  computed: {
    availableTasksCount() {
      var count = 0;

      this.taskCounts['PERMITTED'].forEach(task => {
        count += task.count;
      });

      return count;
    },
    canApplyTasksCount() {
      var count = 0;

      this.taskCounts['CAN_APPLY'].forEach(function (task) {
        count += task.count;
      });

      return count;
    },
    closedTasksCount() {
      var count = 0;

      this.taskCounts['CLOSED'].forEach(function (task) {
        count += task.count;
      });

      return count;
    },
    availableTasksList() {
      return this.taskCounts['PERMITTED'].filter(function (task) {
        return task.count >= 1;
      })
    },
    canApplyTasksList() {
      return this.taskCounts['CAN_APPLY'].filter(function (task) {
        return task.count >= 1;
      })
    },
    closedTasksList() {
      return this.taskCounts['CLOSED'].filter(function (task) {
        return task.count >= 1;
      })
    },
  },
  methods: {
    crLink(taskType, type) {
      var url = '';

      if (taskType.includes(('AP_'))) {
        url = '/manager/applications?applicationTypes=APPLY_TO_TASK_TYPE&taskTypesToBeAppliedFor=' + taskType;
      } else if (taskType.includes(('CR_'))) {
        taskType = taskType.replaceAll('CR_', '');
        url = '/manager/contentreviews?type=' + taskType + '&reviewStatus=' + type;
      }

      return url;
    },
    knowledgeAreaLink(type) {
      return '/managerKnowledgeArea?jobType=' + type;
    },
    taskTitle(type) {
      return i18n.global.t('taskTypes["' + type +'"]')
    }
  }
}
</script>

<template>
  <div class="card">
    <Accordion multiple expandIcon="fa fa-caret-down" collapseIcon="fa fa-caret-up">
      <AccordionPanel value="0">
        <AccordionHeader>{{$t('managerLounge.availableTasksForYou')}} ({{ availableTasksCount }})</AccordionHeader>
        <AccordionContent>
          <ul class="task-links">
            <li v-for="task in availableTasksList" class="task-link" :key="task.taskType">
              <a target="_blank"
                 :href="crLink(task.taskType, 'CURRENT')"
                 class="task-link__text"
              >
                {{ taskTitle(task.taskType) }} ({{ task.count }})
              </a>
            </li>
          </ul>
        </AccordionContent>
      </AccordionPanel>
      <AccordionPanel value="1">
        <AccordionHeader>{{$t('managerLounge.tasksYouCanApplyFor')}} ({{ canApplyTasksCount }})</AccordionHeader>
        <AccordionContent>
          <ul class="task-links">
            <li v-for="task in canApplyTasksList" class="task-link" :key="task.taskType">
              <a :href="knowledgeAreaLink(task.taskType)"
                 target="_blank"
                 class="task-link__text"
              >{{ taskTitle(task.taskType) }} ({{ task.count }})
              </a>
            </li>
          </ul>
        </AccordionContent>
      </AccordionPanel>
      <AccordionPanel value="2">
        <AccordionHeader>{{$t('managerLounge.tasksCurrentlyClosedForYou')}} ({{ closedTasksCount }})</AccordionHeader>
        <AccordionContent>
          <ul class="task-links">
            <li v-for="task in closedTasksList" class="task-link"
                :key="task.taskType"
            >
              <span class="task-link__text">{{ taskTitle(task.taskType) }} ({{ task.count }})</span>
            </li>
          </ul>
        </AccordionContent>
      </AccordionPanel>
    </Accordion>
  </div>
</template>

<style lang="scss">
@import "~@/assets/scss/base/variables";

.task-links {
  margin-top: 15px;
  .task-link {
    margin: 0 0 15px 25px;
    font-size: 20px;
    font-weight: 600;
    color: $purple;
    cursor: pointer;
    list-style-type: disc;

    &__text {
      font-size: 20px;
      font-weight: 600;
      color: $purple;
      cursor: pointer;
    }
  }
}

.p-accordionpanel {
  margin-bottom: 20px !important;

  .p-accordionheader-toggle-icon {
    margin-left: 10px;
    color: $purple !important;
  }
}

.p-accordionheader {
  justify-content: flex-start !important;
  padding: 0 !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #3c3c3c !important;

  &:hover {
    background: transparent !important;
  }
}

.p-accordioncontent-content {
  padding: 0 !important;
}

.p-accordionheader,
.p-accordioncontent-content {
  border-color: transparent !important;
}
</style>