import {defineStore, mapActions} from "pinia";
import axios from "axios";
import router from "@/router";
import {VersionedEntityStatus} from "@/store/model/VersionedEntityStatus";
import {Proposal} from "@/store/model/Proposal";
import {CmsDocumentContentStatus, CmsDocumentContentType, Content} from "@/store/model/Content";
import {ProposalCreate} from "@/store/model/ProposalCreate";
import {ProposalUpdate} from "@/store/model/ProposalUpdate";
import {AttachmentName} from "@/store/model/Attachment";
import {ProposalLogbookItem} from "@/store/model/LogbookItem";
import {documentStore} from "@/store/documentStore";
import {baseLoaderStore} from "@/store/baseLoaderStore";
import {argumentStore} from "@/store/argumentStore";

export const proposalStore = defineStore('proposalStore', {
    state: () => ({
        proposalInfo: null,
        proposalNotAvailable: false,
        proposalOpinionsOfOthers: [],
        lifeCycleModalState: false,
        actualProposalModalState: false,
        paramActualPropInfo: null,
        actualProposalContent: null,
        proposersModalState: false,
        proposalLogbookModalState: false,
        proposalLogbookData: null,

        //Create proposal
        searchPropString: '',
        searchingState: false,
        existingProposals: [],
        createPropFormViewState: false,
        actualExamplesModalState: false,

        //Edit proposal
        proposalTitleHasChanged: false,
        actualProposalHasChanged: false,
        proposalImportancyChanged: false,
        proposalArgumentsChanged: false,
        proposalNeedUpdateModalState: false,

        editProposalViewState: false,
        editedProposalData: null,
        selectedActualType: { name: 'Add text', value: 'HTML_TEXT' },
        actualProposalTextVal: '',
        actualProposalFile: null,
        savedDraftModalState: false,

        //Proposal arguments
        proposalArguments: []
    }),
    getters: {
        actualProposalInfo() {
            return this.paramActualPropInfo || this.proposalInfo.actualProposal;
        },
        editProposalHasChanges() {
            return this.proposalTitleHasChanged
                || this.actualProposalHasChanged
                || this.proposalImportancyChanged
                || this.proposalArgumentsChanged;
        },
        isDraftLoaded() {
            return this.proposalInfo && this.proposalInfo.status === VersionedEntityStatus.DRAFT;
        }
    },
    actions: {
        ...mapActions(baseLoaderStore, [
            'showBaseLoader',
            'hideBaseLoader'
        ]),
        ...mapActions(documentStore, [
            'createTextDocument',
            'updateTextDocument',
            'createAttachmentDocument',
            'updateAttachmentDocument'
        ]),
        ...mapActions(argumentStore, [
            'createNewArgument',
            'updateArgument',
            'setArgumentContent',
            'getArguments'
        ]),
        setAsModified(entityName) {
            this[entityName] = true;
        },
        resetStoreData() {
            this.editedProposalData = null;
            this.proposalArguments = [];
            this.proposalTitleHasChanged = false;
            this.actualProposalHasChanged = false;
            this.proposalImportancyChanged = false;
            this.proposalArgumentsChanged = false;
        },

        // Actual proposal
        setActualProposalType(type) {
            this.selectedActualType = type;
        },
        setActualProposalText(text) {
            this.actualProposalTextVal = text;
        },
        setActualProposalFile(file) {
            this.actualProposalFile = file;
        },
        setUpdateActualProposalAction(proposalId, contentId) {
            return axios.put(`/api/proposals/${proposalId}/actual?contentId=${contentId}`);
        },
        setUpdateTextActualProposal(contentId) {
            const status = this.proposalInfo.actualProposal ? this.proposalInfo.actualProposal.status : null;
            const title = this.proposalInfo ? this.proposalInfo.title : "Actual proposal text";
            if (contentId && status === CmsDocumentContentStatus.DRAFT) {
                return  this.updateTextDocument(contentId, {
                    mimeType: "text/html",
                    text: this.actualProposalTextVal,
                    title: title,
                });
            } else {
                const contentNumber = this.proposalInfo.actualProposal ? this.proposalInfo.actualProposal.contentNumber : null;
                return this.createTextDocument({
                    mimeType: "text/html",
                    text: this.actualProposalTextVal,
                    title: title,
                }, contentNumber).then((resp)=> {
                    return this.setUpdateActualProposalAction(this.editedProposalData.id, resp.data.id)
                        .then((res)=> {
                            this.proposalInfo = new Proposal(res.data);
                            this.editedProposalData = new Proposal(res.data);
                        });
                });
            }
        },
        setUpdatePdfActualProposal(contentId) {
            const status = this.proposalInfo.actualProposal ? this.proposalInfo.actualProposal.status : null;
            if (contentId && status === CmsDocumentContentStatus.DRAFT) {
                const attachmentId = this.editedProposalData.actualProposal.attachments.find(a => a.name === AttachmentName.PDF_FILE).id;
                return this.updateAttachmentDocument(contentId, attachmentId, this.actualProposalFile)
                    .then((resp)=> {
                        this.setActualProposalFile(resp.data.attachments.find(a => a.name === AttachmentName.PDF_FILE));
                        this.proposalInfo.actualProposal = new Content(resp.data);
                        this.editedProposalData.actualProposal = new Content(resp.data);
                    });
            } else {
                const title = this.proposalInfo ? this.proposalInfo.title : "Actual proposal file";
                const contentNumber = this.proposalInfo.actualProposal ? this.proposalInfo.actualProposal.contentNumber : null;
                return this.createAttachmentDocument(this.actualProposalFile, CmsDocumentContentType.PDF, title, contentNumber)
                    .then((resp)=> {
                        this.setUpdateActualProposalAction(this.proposalInfo.id, resp.data.id)
                            .then((res)=> {
                                this.proposalInfo = new Proposal(res.data);
                                this.editedProposalData = new Proposal(res.data);
                            });
                });
            }
        },
        processActualProposalSaving() {
            const contentId = this.proposalInfo.actualProposal ? this.proposalInfo.actualProposal.contentId : null;

            if (this.actualProposalHasChanged) {
                if (this.selectedActualType.value === 'HTML_TEXT') {
                    if (this.actualProposalTextVal) {
                        return this.setUpdateTextActualProposal(contentId);
                    }
                } else if (this.selectedActualType.value === 'PDF') {
                    if (this.actualProposalFile) {
                        return this.setUpdatePdfActualProposal(contentId);
                    }
                }
            } else {
                return Promise.resolve();
            }
        },
        async processArgumentsSaving() {
            let readyForSave = [];

            this.proposalArguments.forEach((arg) => {
                const argHasContent = !!arg.argumentDetailed;

                if (!arg.changedTitle && !arg.changedContent) {
                    return false;
                }

                let argData = {
                    "creatorId": arg.creatorId || arg.creator.id,
                    "creatorType": arg.creatorType || arg.creator.type,
                    "order": arg.order,
                    "proposalId": this.editedProposalData.id,
                    "title": arg.title
                };

                if (arg.id) {
                    delete argData.proposalId;
                    argData.argumentNumber = arg.argumentNumber;

                    readyForSave.push(this.createNewArgument(argData).then((argResp)=> {
                        if (arg.type === 'TEXT') {
                            if (argHasContent && arg.argumentDetailed.status === CmsDocumentContentStatus.DRAFT) {
                                return this.updateTextDocument(argResp.data.argumentDetailed.contentId, {
                                    mimeType: "text/html",
                                    text: arg.argText,
                                    title: arg.title,
                                });
                            } else {
                                const contentNumber = arg.argumentDetailed ? arg.argumentDetailed.contentNumber : null;
                                return this.createTextDocument({
                                    mimeType: "text/html",
                                    text: arg.argText,
                                    title: arg.title,
                                }, contentNumber).then((docResp)=> {
                                    return this.setArgumentContent(argResp.data.id, docResp.data.id);
                                });
                            }

                        } else if (arg.type === 'VIDEO') {
                            //TOOD
                        }
                    }));
                } else {
                    readyForSave.push(this.createNewArgument(argData).then((argResp)=> {
                        if (arg.type === 'TEXT') {
                            return this.createTextDocument({
                                mimeType: "text/html",
                                text: arg.argText,
                                title: arg.title,
                            }).then((docResp)=> {
                                return this.setArgumentContent(argResp.data.id, docResp.data.id);
                            })
                        } else if (arg.type === 'VIDEO') {
                            if (arg.attachedVideoDoc) {
                                return this.setArgumentContent(argResp.data.id, arg.attachedVideoDoc.id);
                            }
                        }
                    }))
                }
            });

            return await Promise.all(readyForSave);
        },
        async processProposalSaving(actionType) {
            let readyForSave = [];

            if (this.isDraftLoaded) {
                this.showBaseLoader();
                readyForSave.push(await this.processActualProposalSaving());
                readyForSave.push(await this.processArgumentsSaving());
                await Promise.all(readyForSave).then(()=> {
                    switch (actionType) {
                        case 'DRAFT':
                            this.saveProposal();
                            break;
                        case 'PUBLISH':
                            this.publishProposal();
                            break;
                    }
                });
            } else {
                throw new Error("Illegal edited proposal state: " + this.proposalInfo.status);
            }
        },
        saveProposal() {
            this.updateProposalAction(true).then(()=> {
                this.successfullySaveProposal();
                this.toggleSavedDraftModalState();
            });
        },
        publishProposal() {
            return this.updateProposalAction(this.editProposalHasChanges).then(()=> {
                return this.publishProposalAction()
                    .then(()=> {
                        this.successfullySaveProposal();
                    })
                    .catch((error)=> {
                        const statusCode = error.status;
                        if (statusCode === 409) {
                            this.hideBaseLoader();
                            this.toggleProposalNeedUpdateModalState();
                        }
                    })
            })
        },
        successfullySaveProposal() {
            const current = this.proposalInfo.versions.find(p => p.status === VersionedEntityStatus.CURRENT);
            router.push({ name: 'ProposalView', params: { id: (current ? current.id : this.proposalInfo.id) } });
            this.resetStoreData();
            this.setProposalArguments([]);
            this.closeEditProposalView();
            this.closeCreatePropFormView();
            this.hideBaseLoader();
            if (current) {
                this.getProposalInfo(current.id);
            }
        },
        getProposalInfoByIssueNumber(proposalIssueNumber, proposalVersion) {
            return axios.get(`/api/proposals?issueNumbers=${proposalIssueNumber}&page=0&size=10`)
                .then((res) => {
                    let proposalId = null;
                    if (res.data.content.length > 0) {
                        proposalId = res.data.content[res.data.content.length - 1].id;
                    }
                    if (proposalId) {
                        return this.getProposalInfo(proposalId).then(()=> {
                            if (proposalVersion) {
                                const versionProposal = this.proposalInfo.versions.find((proposal)=> proposal.version === proposalVersion);
                                if (versionProposal) {
                                    return this.getProposalInfo(versionProposal.id);
                                }
                            }
                        });
                    } else {
                        this.proposalNotAvailable = true;
                    }
                })
                .catch(error => console.log(error));
        },
        getProposalInfo(proposalId) {
            return axios.get(`/api/proposals/${proposalId}`)
                .then((res) => {
                    this.proposalInfo = new Proposal(res.data);
                    this.editedProposalData = new Proposal(res.data);
                })
                .catch((error) => {
                    const errorStatus = error.response.status;
                    this.proposalNotAvailable = (errorStatus === 400 || errorStatus === 404);
                }) ;
        },
        getDraftProposalInfo() {
            if (!this.isDraftLoaded) {
                const draftProposalVersion = this.proposalInfo.versions.find((v) => v.status === VersionedEntityStatus.DRAFT);
                if (draftProposalVersion) {
                    return this.getProposalInfo(draftProposalVersion.id);
                } else {
                    return this.createProposalVersionAction();
                }
            } else {
                return Promise.resolve();
            }
        },
        getActualTextContent(documentId) {
            return axios.get(`/api/cms/${documentId}/text`)
                .then((res) => {
                    this.actualProposalContent = res.data;
                })
                .catch(error => console.log(error));
        },
        getActualDocContent(docHash) {
            return axios.get(`/api/attachment/${docHash}/download`)
                .then((res) => {
                    this.actualProposalContent = res.data;
                })
                .catch(error => console.log(error));
        },
        getProposalOpinionsOfOthersAction(proposalId) {
            return axios.get(`/api/opinion/others?proposalId=${proposalId}`, {
                data: {},
            }).then((res) => {
                this.proposalOpinionsOfOthers = res.data;
            });
        },
        toggleLifeCycleModal() {
            this.lifeCycleModalState = !this.lifeCycleModalState;
        },
        openActualProposalModal(paramActualPropInfo) {
            if (paramActualPropInfo) {
                this.paramActualPropInfo = paramActualPropInfo;
            }

            this.actualProposalModalState = true;
        },
        closeActualProposalModal() {
            this.actualProposalModalState = false;
            this.paramActualPropInfo = null;
        },
        toggleProposersModal() {
            this.proposersModalState = !this.proposersModalState;
        },

        //Create proposal
        setSearchPropString(val) {
            this.searchPropString = val;
        },
        searchExistingProposals() {
            this.searchingState = true;
            return axios.get(`/api/proposals?&title=` + this.searchPropString)
                .then((res) => {
                    this.existingProposals = res.data.content;
                    this.searchingState = false;
                })
                .catch(error => console.log(error));
        },
        openCreatePropFormView() {
            this.createPropFormViewState = true;
        },
        closeCreatePropFormView() {
            this.createPropFormViewState = false;
        },
        toggleActualExamplesModal() {
            this.actualExamplesModalState = !this.actualExamplesModalState;
        },

        //Edit proposal
        closeEditProposalView() {
            this.editProposalViewState = false;
        },
        toggleEditProposalState() {
            this.editProposalViewState = !this.editProposalViewState;
        },
        setEditPropValue(prop, value) {
            this.editedProposalData[prop] = value;
        },
        setProposalArguments(argumentsList) {
            this.proposalArguments = argumentsList;
        },
        addProposalArgument(argData) {
            this.proposalArguments.push(argData);
        },
        removeProposalArgument(argIndex) {
            this.proposalArguments = this.proposalArguments.filter((item, index)=> {
                return index !== argIndex;
            })
        },
        removeProposalArgumentAction() {
            //TODO remove action
        },
        async createProposalVersionAction() {
            const data = new ProposalCreate(
                this.editedProposalData.title,
                this.editedProposalData.getProposerIdOr(),
                this.editedProposalData.getCreatorTypeOr(),
                this.editedProposalData.getImportanceTypeOr(),
                this.editedProposalData.issueNumber
            );
            return axios.post(`/api/proposals`, data).then((res) => {
                this.proposalInfo = new Proposal(res.data);
                this.editedProposalData = new Proposal(res.data);
            });
        },
        async createNewProposalAction(title, proposerId, creatorType, importanceType) {
            const data = new ProposalCreate(
                title,
                proposerId,
                creatorType,
                importanceType,
                null
            );
            return axios.post(`/api/proposals`, data).then((res) => {
                this.proposalInfo = new Proposal(res.data);
                this.editedProposalData = new Proposal(res.data);
            });
        },
        updateProposalAction(hasChanges) {
            const proposalId = this.editedProposalData.id;
            const data = new ProposalUpdate(
                this.editedProposalData.title,
                this.editedProposalData.getImportanceTypeOr(),
                this.editedProposalData.changeType,
                null // TODO: valekseev: 1/20/2025: retrieve labels to attach
            );
            return hasChanges ? axios.put(`/api/proposals/${proposalId}`, data).then((res) => {
                this.proposalInfo = new Proposal(res.data);
                this.editedProposalData = new Proposal(res.data);
            }) : Promise.resolve();
        },
        publishProposalAction() {
            const proposalId = this.editedProposalData.id;
            return axios.put(`/api/proposals/${proposalId}/publish`).then((res) => {
                this.proposalInfo = new Proposal(res.data);
                this.editedProposalData = new Proposal(res.data);
            });
        },
        toggleSavedDraftModalState() {
            this.savedDraftModalState = !this.savedDraftModalState;
        },
        toggleProposalNeedUpdateModalState() {
            this.proposalNeedUpdateModalState = !this.proposalNeedUpdateModalState;
        },

        //Proposal logbook
        getProposalLogbookDataAction(proposalId) {
            return axios.get(`/api/proposals/${proposalId}/logbook`).then((resp)=> {
                this.proposalLogbookData = resp.data.map(i => new ProposalLogbookItem(i)).toSorted((a, b) => b.version - a.version);
            });
        },
        toggleProposalLogbookModalState() {
            this.proposalLogbookModalState = !this.proposalLogbookModalState;
        }
    }
})