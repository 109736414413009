<script>
import {mapActions, mapState} from "pinia";
import {ImportanceType} from "@/store/model/ImportanceType";
import {proposalStatusText} from "@/utils/ProposalUtils";
import {proposalStore} from "@/store/proposalStore";
import {baseLoaderStore} from "@/store/baseLoaderStore";
import Dialog from "primevue/dialog";
import Popper from "vue3-popper";

export default {
  name: "ProposalLogbookModal",
  components: {
    Dialog,
    Popper
  },
  computed: {
    ...mapState(proposalStore, [
        'proposalLogbookModalState',
        'proposalInfo',
        'proposalLogbookData'
    ]),
    modalState: {
      get() {
        return this.proposalLogbookModalState;
      },
      set() {
        this.toggleProposalLogbookModalState();
      },
    },
  },
  methods: {
    ...mapActions(baseLoaderStore, [
      'showBaseLoader',
      'hideBaseLoader'
    ]),
    ...mapActions(proposalStore, [
      'toggleProposalLogbookModalState',
      'getProposalLogbookDataAction',
      'openActualProposalModal'
    ]),

    openActualProp(changed, paramActualProp, item) {
      const missingActual = !item.proposalActual.actual;

      if (changed) {
        if (missingActual) {
          this.$parent.$refs.proposalGeneralInfo.$refs.proposalGeneralInfoAdditional.missingActualProposalModalState = true;
        } else {
          this.showBaseLoader()
          this.openActualProposalModal(paramActualProp);
        }
      }
    },
    proposalStatusLabel(status, forumName, committeeType) {
      return proposalStatusText(status, forumName, committeeType);
    },
    importanceCount(status) {
      if (status === ImportanceType.UNIMPORTANT) {
        return 1;
      } else if (status === ImportanceType.SOME_IMPORTANCE) {
        return 2;
      } else if (status === ImportanceType.IMPORTANT) {
        return 3;
      } else if (status === ImportanceType.VERY_IMPORTANT) {
        return 4;
      } else if (status === ImportanceType.MATTER_OF_THE_HEART) {
        return 5;
      }
    }
  },
  mounted() {
    this.showBaseLoader();
    this.getProposalLogbookDataAction(this.proposalInfo.id).then(()=> {
      this.hideBaseLoader();
    }).catch(()=> {
      this.hideBaseLoader();
    })
  }
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          class="proposal-logbook-modal"
          modal
          :header="$t('proposal.logbook.proposalLogbook')"
  >
    <div>
      <div class="pl-general-info">
        <div>
          <span class="mr-5">{{$t('proposal.logbook.proposalLogbookFor')}}</span>
          <a :href="`/proposal/${proposalInfo.id}`" target="_blank">
            {{proposalInfo.title}}
          </a>
        </div>
        <div>
          <span>{{$t('proposal.logbook.issueNumber')}}</span>
          <a :href="`/proposal/${proposalInfo.issueNumber}`" target="_blank">
            #{{proposalInfo.issueNumber}}
          </a>
        </div>
      </div>
      <div class="pl-header">
        <div class="pl-col pl-col--date">
          {{$t('proposal.logbook.dateTime')}}
        </div>
        <div class="pl-col pl-col--changes">
          {{$t('proposal.logbook.changesTo')}}
        </div>
      </div>

      <div class="pl-body">
        <div v-for="(item) in proposalLogbookData"
             class="pl-row"
             :key="item.proposalId"
        >
          <div class="pl-col pl-col--date">
            <div class="date-time-info">
              <a :href="`/proposal/${item.proposalId}`" target="_blank">
                <i class="fas fa-external-link-square-alt date-time-info__icon"></i>
              </a>
              <div class="date-time-info__text">
                <span>{{ item.dateTime }}</span>
              </div>
            </div>
          </div>
          <template v-if="item.version !== 1">
            <div class="pl-col pl-col--changes">
              <div class="logbook-data-list">
                <!-- Proposal title -->
                <Popper :hover="true" arrow placement="top" class="popper-dark" :disabled="!item.title.changed">
                  <div class="logbook-data-item" :class="{'logbook-data-item--active': item.title.changed}">
                    {{$t('proposal.logbook.header')}}
                  </div>
                  <template #content>
                    <div class="popper-content">
                      <div class="popper-content-item">
                        <span class="popper-content-item__title">
                          {{$t('proposal.logbook.updated')}}
                        </span>
                        <span class="popper-content-item__text">{{ item.title.actual }}</span>
                      </div>
                      <div class="popper-content-item">
                        <span class="popper-content-item__title">
                          {{$t('proposal.logbook.previous')}}
                        </span>
                        <span class="popper-content-item__text">{{ item.title.previous }}</span>
                      </div>
                    </div>
                  </template>
                </Popper>
                <!-- Proposal actual proposal -->
                <Popper :hover="true" arrow placement="top" class="popper-dark" :disabled="!item.proposalActual.changed">
                  <div class="logbook-data-item logbook-data-item--clickable"
                       :class="{'logbook-data-item--active': item.proposalActual.changed}"
                       @click="openActualProp(item.proposalActual.changed, item.proposalActual.actual, item)"
                  >
                    {{$t('proposal.logbook.actualProposal')}}
                  </div>
                  <template #content>
                    <div class="popper-content">
                      <div class="popper-content-item m-0">
                        <span class="popper-content-item__text">{{$t('proposal.logbook.clickToSeeDetails')}}</span>
                      </div>
                    </div>
                  </template>
                </Popper>
                <!-- Proposal importance -->
                <Popper :hover="true" arrow placement="top" class="popper-dark" :disabled="!item.importance.changed">
                  <div class="logbook-data-item" :class="{'logbook-data-item--active': item.importance.changed}">
                    {{$t('proposal.logbook.importance')}}
                  </div>
                  <template #content>
                    <div class="popper-content">
                      <div class="popper-content-item">
                        <span class="popper-content-item__title">
                          {{$t('proposal.logbook.updated')}}
                        </span>
                        <span class="popper-content-item__text">
                          <span class="popper-content-item__text d-flex">
                            <span class="mr-5">
                              {{$t('proposal.logbook.importance')}}
                            </span>
                            <div class="importance-info">
                              <font-awesome-icon :icon="['fas', 'heart']" class="importance-info__icon"/>
                              <span class="importance-info__number">{{importanceCount(item.importance.actual)}}</span>
                            </div>
                          </span>
                        </span>
                      </div>
                      <div class="popper-content-item">
                        <span class="popper-content-item__title">
                          {{$t('proposal.logbook.previous')}}
                        </span>
                        <span class="popper-content-item__text">
                          <span class="popper-content-item__text d-flex">
                            <span class="mr-5">
                              {{$t('proposal.logbook.importance')}}
                            </span>
                            <div class="importance-info">
                              <font-awesome-icon :icon="['fas', 'heart']" class="importance-info__icon"/>
                              <span class="importance-info__number">{{importanceCount(item.importance.previous)}}</span>
                            </div>
                          </span>
                        </span>
                      </div>
                    </div>
                  </template>
                </Popper>
                <!-- Proposal status -->
                <Popper :hover="true" arrow placement="top" class="popper-dark" :disabled="!item.extra.status.changed">
                  <div class="logbook-data-item"
                       :class="{'logbook-data-item--active': item.extra.status.changed}"
                  >
                    {{$t('proposal.logbook.status')}}
                  </div>
                  <template #content>
                    <div class="popper-content">
                      <div class="popper-content-item">
                        <span class="popper-content-item__title">
                          {{$t('proposal.logbook.updated')}}
                        </span>
                        <span class="popper-content-item__text">
                           {{proposalStatusLabel(item.extra.status.actual, item.forum.actual.name, (item.extra.committeeType ? item.extra.committeeType.actual : ''))}}
                        </span>
                      </div>
                      <div class="popper-content-item">
                        <span class="popper-content-item__title">
                          {{$t('proposal.logbook.previous')}}
                        </span>
                        <span class="popper-content-item__text">
                          {{proposalStatusLabel(item.extra.status.previous, item.forum.previous.name, (item.extra.committeeType ? item.extra.committeeType.previous : ''))}}
                        </span>
                      </div>
                    </div>
                  </template>
                </Popper>
                <!-- Proposal subject -->
                <Popper :hover="true" arrow placement="top" class="popper-light" :disabled="!item.subjects.changed">
                  <div class="logbook-data-item" :class="{'logbook-data-item--active': item.subjects.changed}">
                    {{$t('proposal.logbook.subject')}}
                  </div>
                  <template #content>
                    <div class="popper-content">
                      <div class="popper-content-item">
                        <span class="popper-content-item__title">
                          {{$t('proposal.logbook.updated')}}
                        </span>
                        <span class="popper-content-item__text">
                          <div class="subjects-wrap">
                            <div v-for="item in  item.subjects.actual" :key="item" class="subject-item">
                              {{item}}
                            </div>
                          </div>
                        </span>
                      </div>
                      <div class="popper-content-item">
                        <span class="popper-content-item__title">
                          {{$t('proposal.logbook.previous')}}
                        </span>
                        <span class="popper-content-item__text">
                          <div class="subjects-wrap">
                            <div v-for="item in  item.subjects.previous" :key="item" class="subject-item">
                              {{item}}
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </template>
                </Popper>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="pl-col pl-col--changes changes-items-list">
              <div class="first-version">{{$t('proposal.logbook.firstVersion')}}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <template #footer>
      <button type="button" class="p-dialog-close-btn" @click="toggleProposalLogbookModalState()">
        {{$t('common.buttons.close')}}
      </button>
    </template>
  </Dialog>
</template>

<style lang="scss">
.proposal-logbook-modal {
  width: 885px;

  .pl-general-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-top: 15px;
    color: #4f4f4f;
  }

  .pl-header {
    display: flex;
    margin-bottom: 17px;
    .pl-col {
      height: 38px;
      background: #7b7b7b;
      border-radius: 3px;
      font-size: 12px;
      font-weight: 600;
      color: #fff;
    }
  }

  .pl-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    &--date {
      width: 92px;
      margin-right: 10px;
    }
    &--changes {
      width: 745px;
    }
    &--details {
      width: 82px;
    }
  }

  .pl-row {
    display: flex;
    margin-bottom: 40px;

    .logbook-data-list {
      display: flex;
      gap: 5px;
    }

    .logbook-data-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 119px;
      min-width: 119px;
      height: 34px;
      background: #f2f2f2;
      border-radius: 4px;
      color: #7b7b7b;
      font-size: 14px;

      &--active {
        background: #a163cd;
        color: #FFFFFF;
        font-weight: 600;
        cursor: pointer;

        &:hover {
          background: #7835a6;
        }

        &.logbook-data-item--clickable {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .date-time-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    &__icon {
      margin-right: 12px;
      color: #7835a6;
    }
    &__text {
      font-size: 12px;
      text-align: right;
      color: #4f4f4f;
      line-height: 1.3;
    }
  }

  .first-version {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 746px;
    height: 76px;
    border-radius: 4px;
    background-color: #f1e8f8;
    font-size: 14px;
    color: #4f4f4f;
  }

  .importance-info {
    display: flex;
    align-items: center;
    color: #fd4847;

    &__icon {
      margin-right: 3px;
    }
    &__number {
      font-size: 14px;
    }
  }

  .subjects-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    width: 300px;
  }

  .subject-item {
    display: flex;
    align-items: center;
    width: max-content;
    height: 24px;
    padding: 3px 9px;
    font-size: 12px;
    color: #4f4f4f;
    border-radius: 2px;
    background-color: #f2f2f2;
  }
}

//Popover styles
.popper-dark {
  --popper-theme-background-color: #4f4f4f;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 2px;
  --popper-theme-padding: 7px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.popper-light {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding:  15px 10px 10px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.popper-content {
  font-size: 12px;

  .popper-content-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    &__title {
      margin-bottom: 5px;
      font-weight: 600;
    }
    &__text {

    }
  }
}
</style>