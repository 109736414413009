"use strict";

import {formatZonedDateTime} from "@/baseUtils";
import {Content} from "@/store/model/Content";
import {ImgLabel} from "@/store/model/ImgLabel";
import {ProposalType} from "@/store/model/Proposal";

export class LogbookItem {

    constructor(data) {
        this.previous = data.previous;
        this.actual = data.actual;
        this.changed = data.changed;
    }
}

export class BaseExtraProposalLogbookItem {

    constructor(data) {
        this.status = new LogbookItem(data.status); // ProposalOfficialStatus
    }
}

export class RiksdagenExtraProposalLogbookItem extends BaseExtraProposalLogbookItem {

    constructor(data) {
        super(data);
        this.spokes = new LogbookItem(
            {
                previous: data.spokes.previous ? data.spokes.previous.map(v => new ImgLabel(v)) : null,
                actual: data.spokes.actual.map(v => new ImgLabel(v)),
                changed: data.spokes.changed
            }
        );
        this.committeeDecisionDate = new LogbookItem(
            {
                previous: data.committeeDecisionDate.previous ? formatZonedDateTime(data.committeeDecisionDate.previous) : null,
                actual: formatZonedDateTime(data.committeeDecisionDate.actual),
                changed: data.committeeDecisionDate.changed
            }
        );
        this.riksdagenDecisionDate = new LogbookItem(
            {
                previous: data.riksdagenDecisionDate.previous ? formatZonedDateTime(data.riksdagenDecisionDate.previous) : null,
                actual: formatZonedDateTime(data.riksdagenDecisionDate.actual),
                changed: data.riksdagenDecisionDate.changed
            }
        );
        this.committeeType = new LogbookItem(data.committeeType); // CommitteeType
    }
}

export class LogbookNestedArgumentItem {

    constructor(data) {
        this.id = data.id;
        this.argumentNumber = data.argumentNumber;
        this.version = data.version;
        this.title = data.title;
    }
}

export class ProposalLogbookItem {

    constructor(data) {
        this.proposalId = data.proposalId;
        this.version = data.version;
        this.dateTime = formatZonedDateTime(data.dateTime);
        this.changeType = data.changeType; // EntityChangeType
        this.title = new LogbookItem(data.title);
        this.proposalActual = new LogbookItem(
            {
                previous: data.proposalActual.previous ? new Content(data.proposalActual.previous) : null,
                actual: data.proposalActual.actual ? new Content(data.proposalActual.actual) : null,
                changed: data.proposalActual.changed
            }
        );
        this.proposer = new LogbookItem(
            {
                previous: data.proposer.previous ? new ImgLabel(data.proposer.previous) : null,
                actual: new ImgLabel(data.proposer.actual),
                changed: data.proposer.changed
            }
        );
        this.importance = new LogbookItem(data.importance);
        this.forum = new LogbookItem(
            {
                previous: data.forum.previous ? new ImgLabel(data.forum.previous) : null,
                actual: new ImgLabel(data.forum.actual),
                changed: data.forum.changed
            }
        );
        this.arguments = new LogbookItem(
            {
                previous: data.previous ? data.arguments.previous.map(v => new LogbookNestedArgumentItem(v)) : null,
                actual: data.arguments.actual.map(v => new LogbookNestedArgumentItem(v)),
                changed: data.arguments.changed
            }
        );
        this.subjects = new LogbookItem(data.subjects);
        this.extra = data.type === ProposalType.PROPOSAL ? new BaseExtraProposalLogbookItem(data.extra) : new RiksdagenExtraProposalLogbookItem(data.extra);
    }
}