<script>
import i18n from "@/i18n";
import axios from "axios";
import {mapActions, mapState} from "pinia";
import {managerStore} from "@/store/managerStore";
import {baseLoaderStore} from "@/store/baseLoaderStore";
import InputNumber from "primevue/inputnumber";
export default {
  name: "ManagerRatesItem",
  components: {
    InputNumber
  },
  props: {
    itemData: {
      type: Object
    },
    averageTime: {
      type: Number
    },
    disableEdit: {
      type: Boolean
    }
  },
  data: function () {
    return {
      dataForSave: null,
      editModeState: false,
      newTime: null,
      itemCompensationInfo: null,
    }
  },
  watch: {
    editModeState(val) {
      if (val) {
        this.$emit('on-edit');
      } else {
        this.$emit('on-cancel-edit');
      }
    }
  },
  computed: {
    ...mapState(managerStore,[
      'getRateVersionsAction',
      'compensationInfo'
    ]),
    nextRateStartDate() {
      return this.itemData.nextTaskRate ? this.itemData.nextTaskRate.dateStart : '';
    },
    taskAverageTime() {
      return this.averageTime || 0;
    }
  },
  methods: {
    ...mapActions(baseLoaderStore, [
      'showBaseLoader',
      'hideBaseLoader'
    ]),
    ...mapActions(managerStore,[
      'toggleRatesLogModalState',
      'createNewRatePeriod'
    ]),
    taskTitle(item) {
      const titleSuffix = item.specialCase === 0 ? ` - ${i18n.global.t('editRates.default')}` : ` - ${i18n.global.t('editRates.specialCase')} ${item.specialCase}`;
      const taskTypeTitle = i18n.global.t(`taskTypes['${this.itemData.type}']`);

      return taskTypeTitle + titleSuffix;
    },
    taskExpectedTime(item) {
      return item.expectedTimeSeconds;
    },
    taskCompensation(taskExpectedTime) {
      return Number(taskExpectedTime) * this.compensationInfo.compensationVal;
    },
    nextRatePeriodDate(item) {
      const nextTaskRate = this.itemData.nextTaskRate;

      if (nextTaskRate) {
        const nextRateDate = nextTaskRate.dateStart;
        const nextRateVal = nextTaskRate.values[item.specialCase].value.toFixed(2);
        const nextRateCurrency = nextTaskRate.values[item.specialCase].currency;
        return `${nextRateDate} - ${nextRateVal} ${nextRateCurrency}`;
      } else {
        return '--';
      }
    },
    toggleEditMode: function () {
      this.editModeState = !this.editModeState;
    },
    seeLog(item) {
      this.toggleRatesLogModalState({
        type: this.itemData.type,
        taskTitle: this.taskTitle(item),
        specialCase: item.specialCase
      });
    },
    changeRate() {
      if (!this.disableEdit) {
        if (this.itemData.nextTaskRate) {
          this.dataForSave.values = this.itemData.nextTaskRate.values;
        }
        this.toggleEditMode();
      }
    },
    async updateRateAction(refreshData) {
      const type = this.itemData.type;

      this.showBaseLoader();
      if (this.nextRateStartDate) {
        await this.updateAllRates(null, refreshData);
      } else {
        await this.createNewRatePeriod(type).then((res)=> {
           this.updateAllRates(res.data.dateStart, refreshData);
        });
      }
    },

    async updateAllRates(paramDateStart, refreshData) {
      let ratesItemsPromises = [];

      for (let i = 0; i < this.dataForSave.values.length; i++) {
        const dateStart = paramDateStart || this.nextRateStartDate;
        const rateItem = this.dataForSave.values[i];
        const url = '/api/manager/compensation/rates';

        const saveData = {
          dateStart: dateStart,
          type: this.itemData.type,
          compensationBasedUpon: this.compensationInfo.compensationBase,
          value: Number(rateItem.expectedTimeSeconds) * this.compensationInfo.compensationVal,
          specialCase: rateItem.specialCase,
          expectedTimeSeconds: rateItem.expectedTimeSeconds,
          socialTaxRate: this.compensationInfo.socialTaxRate,
          vacationPay: this.compensationInfo.vacationPay
        }

        ratesItemsPromises.push(
            await axios.put(url + '?' + new URLSearchParams(saveData)).catch((error) => {
              console.log(error);
            }),
        );

        await Promise.all(ratesItemsPromises).then(() => {
          ratesItemsPromises = [];
          this.editModeState = false;
          if (refreshData) {
            this.$emit('on-update');
            this.hideBaseLoader();
          }
        })

      }
    },
  },
  mounted() {
    this.dataForSave = Object.assign({}, this.itemData);
    this.itemCompensationInfo = Object.assign({}, this.compensationInfo);
  }
}
</script>

<template>
<div class="manager-rates-row manager-rates-table__row-wrap" :class="{'active': editModeState}">
  <div v-for="item in itemData.values"
       :key="item.specialCase"
       class="manager-rates-table__row"
  >
    <div class="manager-rates-table__col task-type">
      {{taskTitle(item)}}
    </div>
    <div class="manager-rates-table__col expected-time">
      {{taskExpectedTime(item)}}
    </div>
    <div class="manager-rates-table__col spend-time">
      {{taskAverageTime}}
    </div>
    <div class="manager-rates-table__col current-compensation">
      {{item.value}}
    </div>
    <div class="manager-rates-table__col upcoming-change">
      {{nextRatePeriodDate(item)}}
    </div>
    <div class="manager-rates-table__col rate-log">
      <span class="rate-item-control" @click="seeLog(item)">{{$t('editRates.seeLog')}}</span>
    </div>
    <div class="manager-rates-table__col action">
      <template v-if="item.specialCase === 0">
        <template v-if="!editModeState">
          <span class="rate-item-control"
                :class="{'rate-item-control--disabled' : disableEdit}"
                @click="changeRate(item)"
          >{{$t('editRates.changeRate')}}</span>
        </template>
      </template>
    </div>
  </div>
  <div v-if="editModeState" class="manager-rates-table__row edit">
    <div class="d-flex w-100 justify-content-between">
      <div class="edit-rate-form">
        <template v-if="itemData.dateEnd">
          <span class="mr-5">{{$t('editRates.rateAppliedFrom')}}</span>
          <span class="text_semi-bold">{{nextRateStartDate}}</span>
        </template>
        <template v-else>
          <span class="edit-rate-form__title">{{$t('editRates.rateAppliedFromNextMonth')}}</span>
        </template>
        <div v-for="(item, index) in dataForSave.values"
             :key="item.specialCase"
             class="edit-rate-form__row"
        >
          <span class="task-type-title">
            <template v-if="index === 0">
              {{$t('editRates.default')}}:
            </template>
            <template v-else>
              {{$t('editRates.specialCase')}} {{item.specialCase}}:
            </template>
          </span>
          <div class="edit-rate-form-group">
            <span class="edit-rate-label">{{$t('editRates.expectedTime')}}:</span>
            <InputNumber v-model="item.expectedTimeSeconds" class="edit-rate-field edit-rate-field--small"/>
          </div>
          <div class="edit-rate-form-group">
            <span class="edit-rate-label">{{$t('editRates.compensation')}} (SEK):</span>
            <InputNumber :modelValue="taskCompensation(item.expectedTimeSeconds)" :maxFractionDigits="2" disabled  class="edit-rate-field edit-rate-field--small"/>
          </div>
          <div class="edit-rate-form-group">
            <span class="edit-rate-label">{{$t('editRates.spendTime')}}:</span>
            <InputNumber v-model="taskAverageTime" disabled :useGrouping="false" class="edit-rate-field edit-rate-field--small"/>
          </div>
        </div>
      </div>
      <template v-if="editModeState">
        <div>
          <span class="rate-item-control mr-20" @click="updateRateAction(true)">
            {{$t('common.buttons.save')}}
          </span>
          <span class="rate-item-control" @click="toggleEditMode()">
            {{$t('common.buttons.cancel')}}
          </span>
        </div>
      </template>
    </div>
  </div>
</div>
</template>

<style lang="scss">
.manager-rates-row {
  .rate-item-control {
    font-weight: 600;
    color: #7835a6;
    cursor: pointer;
    &--disabled {
      cursor: default;
      color: #bbb;
    }
  }

  .edit-rate-form {
    &__title {
      display: inline-block;
      margin-bottom: 10px;
      font-size: 13px;
    }
    &__row {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }
  }

  .task-type-title {
    margin-right: 10px;
    font-weight: 600;
  }

  .edit-rate-form-group {
    display: flex;
    align-items: center;
    margin-right: 25px;
    .edit-rate-label {
      margin-right: 10px;
      font-size: 12px;
      font-weight: 600;
    }
    .edit-rate-field {
      height: 25px;
      text-align: center;
      border-radius: 5px;

      .p-inputtext {
        max-width: 100px;
        border: 1px solid #7F7F7F;
        border-radius: 5px;

        &:focus {
          outline-color: #7835A6;
        }
      }

      :disabled {
        background: #D8D8D8;
      }

      &--small {
        .p-inputtext {
          max-width: 70px;
        }
      }
    }
  }
}
</style>