<script>
import i18n from "@/i18n";
import Multiselect from "vue-multiselect";
import Button from "primevue/button";
export default {
  name: "ManagerListFilter",
  components: {
    Multiselect,
    Button
  },
  data: function () {
    return {
      filterParams: {
        fullNameFilterVal: '',
        emailFilterVal: '',
        filterStatus: {
          name: i18n.global.t('managerView.manager.filtering.filterByStatus.options.active'),
          code: 'ACTIVE'
        },
      },
    }
  },
  computed: {
    filteringStatuses() {
      return [
        {
          name: i18n.global.t('managerView.manager.filtering.filterByStatus.options.all'),
          code: ''
        },
        {
          name: i18n.global.t('managerView.manager.filtering.filterByStatus.options.active'),
          code: 'ACTIVE'
        },
        {
          name: i18n.global.t('managerView.manager.filtering.filterByStatus.options.inactive'),
          code: 'INACTIVE'
        }
      ];
    },
    filteringParams() {
      let params = {};

      if (this.filterParams.fullNameFilterVal) {
        params.fullName = this.filterParams.fullNameFilterVal;
      }

      if (this.filterParams.emailFilterVal) {
        params.email = this.filterParams.emailFilterVal;
      }

      if (this.filterParams.filterStatus) {
        params.status = this.filterParams.filterStatus;
      }

      params.status = this.filterParams.filterStatus.code;

      return params;
    }
  },
  methods: {
    applyFilter: function () {
      this.$emit('get-data', this.filteringParams);
    },
    resetFilters: function () {
      this.filterParams.fullNameFilterVal = '';
      this.filterParams.emailFilterVal = '';
      this.filterParams.filterStatus = {
        name: i18n.global.t('managerView.manager.filtering.filterByStatus.options.active'),
        code: 'ACTIVE'
      };

      this.$emit('get-data', this.filteringParams);
    },
  },
  mounted() {
    window.addEventListener('keydown',  (event)=>  {
      if (event.keyCode === 13) {
        this.applyFilter();
      }
    });
  }
}
</script>

<template>
  <div class="managers-view-filter">
    <div class="managers-list-filters">
      <input type="text"
             v-model="filterParams.fullNameFilterVal"
             :placeholder="$t('managerView.manager.filtering.searchByName')"
             class="filter-input"
      />
      <input type="text"
             v-model="filterParams.emailFilterVal"
             :placeholder="$t('managerView.manager.filtering.searchByEmail')"
             class="filter-input"
      />
      <multiselect v-model="filterParams.filterStatus"
                   label="name"
                   class="multiselect"
                   :placeholder="$t('managerView.manager.filtering.filterByStatus.placeholder')"
                   track-by="code"
                   :show-labels="false"
                   :options="filteringStatuses"
                   :multiple="false"
      ></multiselect>
      <Button :label="$t('managerView.manager.filtering.filter')"
              @click="applyFilter()"
              class="btn"
      />
      <Button :label="$t('managerView.manager.filtering.restore')"
              @click="resetFilters()"
              class="btn"
      />
    </div>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
.managers-view-filter {

  .managers-list-filters {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 15px 0 25px;

    .filter-input {
      display: block;
      height: 30px;
      min-width: 300px;
      margin-right: 15px;
      padding: 8px;
      border-radius: 5px;
      border: 1px solid #e8e8e8;
      background: #fff;
      font-size: 12px;
      box-sizing: border-box;
    }

    .multiselect {
      max-width: 300px;
      min-height: 30px;
      max-height: 30px;
      margin-right: 15px;

      &__select {
        top: 3px;
        height: 28px;
      }

      &__tags {
        min-height: 30px;
        max-height: 30px;
        padding-top: 4px;
      }

      &__placeholder {
        display: block;
        font-size: 12px;
      }

      &__input {
        width: 1px;
        height: 1px;
        opacity: 0;
      }

      &__single {
        font-size: 12px;
      }

      &__tag {
        font-size: 12px;
        background: #cccccc;
      }

      &__tag-icon {
        color: #FFFFFF;
        &:hover {
          background: #cccccc;
          &:after {
            color: #a163cc;
          }
        }
        &:after {
          color: #FFFFFF;
        }
      }

      &__option {
        display: flex;
        align-items: center;
        min-height: auto;
        padding: 5px 10px;
        font-size: 11px;

        &--highlight {
          color: #4f4f4f;
          background: #cccccc;
        }
      }
    }
  }
}
</style>