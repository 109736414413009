<script>
import {mapActions, mapState} from "pinia";
import {managerStore} from "@/store/managerStore";
import {baseLoaderStore} from "@/store/baseLoaderStore";
import ManagerJobsListItem from "@/views/manager/managerView/ManagerTabView/ManagerJobsListItem.vue";
import Button from 'primevue/button';

export default {
  name: "ManagerJobsList",
  components: {
    Button,
    ManagerJobsListItem
  },
  data() {
    return {
      saveBtnState: false,
      changedItems: [],
    }
  },
  computed: {
    ...mapState(managerStore,[
      'jobItemsList'
    ]),
  },
  methods: {
    ...mapActions(baseLoaderStore, [
      'showBaseLoader',
      'hideBaseLoader'
    ]),
    ...mapActions(managerStore, [
        'getJobItems',
        'openJobAction',
        'closeJobAction'
    ]),
    addChangedItem(newItem) {
      if (this.changedItems.length > 0) {
        var itemIsExist = this.changedItems.filter(function (elem) {
          return elem.taskType === newItem.taskType;
        }).length > 0;

        if (itemIsExist) {
          this.changedItems.forEach(function (item) {
            if (item.taskType === newItem.taskType) {
              item.opened = newItem.opened;
            }
          })
        } else {
          this.changedItems.push(newItem);
        }
      } else {
        this.changedItems.push(newItem);
      }

      this.saveBtnState = true;
    },
    async updateJobOpenings() {
      console.log('updateJobOpenings');

      if (this.saveBtnState) {
        this.showBaseLoader();
        this.saveBtnState = false;

        let changedItemsPromises = [];
        let closedItemsPromises = [];


        for (let i = 0; i < this.changedItems.length; i++) {
          if (this.changedItems[i].opened >= '1') {
            changedItemsPromises.push(
                await this.openJobAction(this.changedItems[i].taskType, this.changedItems[i].opened)
            )
          } else {
            closedItemsPromises.push(
                await this.closeJobAction(this.changedItems[i].taskType, this.changedItems[i].opened)
            )
          }
        }

        await Promise.all(changedItemsPromises, closedItemsPromises).then(() => {
          this.saveBtnState = false;
          this.changedItems = [];
          this.hideBaseLoader();
          this.getJobItems();
        })

      }
    }
  },
  beforeMount() {
    this.showBaseLoader();
    this.getJobItems().then(()=> {
      this.hideBaseLoader();
    });
  }
}
</script>

<template>
  <div class="manager-jobs-table">
    <div class="manager-jobs-table-header manager-jobs-table__row">
      <div class="manager-jobs-table-header__col manager-jobs-table__col">
        {{$t('managerView.jobOpenings.taskType')}}
      </div>
      <div class="manager-jobs-table-header__col manager-jobs-table__col">
        {{$t('managerView.jobOpenings.currentlyPermitted')}}
      </div>
      <div class="manager-jobs-table-header__col manager-jobs-table__col">
        {{$t('managerView.jobOpenings.opened')}}
      </div>
      <div class="manager-jobs-table-header__col manager-jobs-table__col">
        {{$t('managerView.jobOpenings.updatedDateTime')}}
      </div>
      <div class="manager-jobs-table-header__col manager-jobs-table__col">
        {{$t('managerView.jobOpenings.lastEditor')}}
      </div>
    </div>
    <manager-jobs-list-item v-for="item in jobItemsList"
                            @job-changed="addChangedItem"
                            :key="item.taskType"
                            :item-data="item"
    ></manager-jobs-list-item>
    <div class="manager-jobs-table-footer">
      <Button :label="$t('common.buttons.save')"
              :class="{ disabled: !saveBtnState }"
              @click="updateJobOpenings"
              :disabled="!saveBtnState"
      />
    </div>
  </div>
</template>

<style lang="scss">
.manager-jobs-table {
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
    &:hover {
      background: #f6efef;
    }
  }

  &__col {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 5px;
  }

  .manager-jobs-table-header {
    &:hover {
      background: transparent;
    }
    &__col {
      font-weight: 600;
    }
  }

  .manager-jobs-table-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
  }
}
</style>