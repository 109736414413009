import {formatZonedDateTime} from "@/baseUtils";

export class EntityVersion {

    constructor(data) {
        this.id = data.id;
        this.version = data.version;
        this.status = data.status; // VersionedEntityStatus
        this.changeType = data.changeType; // EntityChangeType
        this.createDateTime = formatZonedDateTime(data.createDateTime);
    }
}