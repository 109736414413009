<script>
import moment from "moment-timezone";
import {mapActions} from "pinia";
import {managerStore} from "@/store/managerStore";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Toast from "primevue/toast";

export default {
  name: "ManagerCompensationForm",
  components: {
    InputNumber,
    Button,
    Dialog,
    Toast
  },
  data() {
    return {
      monthlyWorkingTime: 160, //Hours
      monthlyCompensation: 0,
      socialTaxRate: 0,
      vacationPay: 0,
      changeCompensationRateModalState: false,
      saveBtnDisableState: true
    }
  },

  computed: {
    currentPayPeriodDate() {
      return moment(new Date).add(1,'months').startOf('month').format('YYYY-MM-DD');
    },
    compensationVal() {
      const monthlyCompensationVal = Number(this.monthlyCompensation);
      const socialTaxRateVal = monthlyCompensationVal / 100 * Number(this.socialTaxRate);
      const vacationPayVal = (monthlyCompensationVal + socialTaxRateVal) / 100 * Number(this.vacationPay);
      const monthlyWorkingTimeSec = Number(this.monthlyWorkingTime) * 60 * 60;

      return (monthlyCompensationVal + socialTaxRateVal + vacationPayVal) / monthlyWorkingTimeSec;
    }
  },
  methods: {
    ...mapActions(managerStore, [
      'setCompensationInfo'
    ]),
    processCompensationInfo() {
      this.changeCompensationRateModalState = true;
    },
    saveCompensationInfo() {
      localStorage.setItem("compensationBaseUponVal", this.monthlyCompensation);
      localStorage.setItem("socialTaxRateVal", this.socialTaxRate);
      localStorage.setItem("vacationPay", this.vacationPay);
      localStorage.setItem("compensationVal", this.compensationVal);

      this.setCompensationInfo({
        compensationBase: this.monthlyCompensation,
        socialTaxRate: this.socialTaxRate,
        vacationPay: this.vacationPay,
        compensationVal: this.compensationVal,
      })

      this.changeCompensationRateModalState = false;
      this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Compensation has been updated', life: 3000 });
      this.saveBtnDisableState = true;

      this.$emit('on-change-compensation');
    },
    getCompensationInfo() {
      this.monthlyCompensation = Number(localStorage.getItem("compensationBaseUponVal") || 0);
      this.socialTaxRate = Number(localStorage.getItem("socialTaxRateVal") || 0);
      this.vacationPay = Number(localStorage.getItem("vacationPay") || 0);

      this.setCompensationInfo({
        compensationBase: this.monthlyCompensation,
        socialTaxRate: this.socialTaxRate,
        vacationPay: this.vacationPay,
        compensationVal: this.compensationVal,
      })
    },
    activateSaveBtn() {
      this.saveBtnDisableState = false;
    }
  },
  mounted() {
    this.getCompensationInfo();
  }
}
</script>

<template>
  <div class="manager-compensation-form">
    <div class="compensation-form-group">
      <span class="compensation-form-label">{{$t('editRates.compensationBasedUpon')}} (SEK):</span>
      <InputNumber v-model="monthlyCompensation"
                   class="compensation-form-input"
                   @update:modelValue="activateSaveBtn()"
      />
    </div>
    <div class="compensation-form-group">
      <span class="compensation-form-label">{{$t('editRates.socialTaxRate')}}:</span>
      <InputNumber v-model="socialTaxRate"
                   :min="0"
                   :max="100"
                   :minFractionDigits="2"
                   :maxFractionDigits="2"
                   @update:modelValue="activateSaveBtn()"
                   class="compensation-form-input compensation-form-input--small"
      />
    </div>
    <div class="compensation-form-group">
      <span class="compensation-form-label">{{$t('editRates.vacationPay')}}:</span>
      <InputNumber v-model="vacationPay"
                   :min="0"
                   :max="100"
                   :minFractionDigits="2"
                   :maxFractionDigits="2"
                   @update:modelValue="activateSaveBtn()"
                   class="compensation-form-input compensation-form-input--small"
      />
    </div>
    <div class="compensation-form-group">
      <span class="compensation-form-label">{{$t('editRates.compensations')}} (SEK)</span>
      <InputNumber v-model="compensationVal"
                   disabled
                   :maxFractionDigits="6"
                   class="compensation-form-input"
      />
    </div>

    <Button :label="$t('common.buttons.save')"
            @click="processCompensationInfo()"
            class="btn"
            :disabled="saveBtnDisableState"
    />

    <Dialog v-model:visible="changeCompensationRateModalState"
            class="change-rate-confirm-modal"
            :header="$t('changeCompensationRateModal.title')"
            modal
    >
      <div>
        {{$t('changeCompensationRateModal.text')}} <span class="text-bold">{{currentPayPeriodDate}}</span>
      </div>
      <template #footer>
        <button type="button" class="p-dialog-close-btn" @click="changeCompensationRateModalState = false">
          {{$t('common.buttons.close')}}
        </button>
        <button class="p-dialog-primary-btn" @click="saveCompensationInfo()">
          {{$t('common.buttons.save')}}
        </button>
      </template>
    </Dialog>

    <Toast position="bottom-right" class="change-compensation-rate-toast"/>
  </div>
</template>

<style lang="scss">
.manager-compensation-form {
  display: flex;
  margin-bottom: 10px;
  background: #EFE4F3;
  padding: 10px;
  border-radius: 10px;

  .compensation-form-group {
    margin-right: 10px;
    display: flex;
    align-items: center;
    .compensation-form-label {
      margin-right: 5px;
      white-space: nowrap;
    }
    .compensation-form-input {
      height: 25px;
      padding: 0 5px;

      .p-inputtext {
        max-width: 140px;
        border: 1px solid #7F7F7F;
        border-radius: 5px;

        &:focus {
          outline-color: #7835A6;
        }
      }

      :disabled {
        background: #D8D8D8;
      }

      &--small {
        .p-inputtext {
          padding: 7px;
          width: 60px;
        }
      }
    }
  }
}

.change-rate-confirm-modal {
  max-width: 600px;
  .p-dialog-content {
    padding: 20px;
  }
}

.change-compensation-rate-toast {
  .p-toast-message {
    background: #EEFAF2;
    border-color: #EEFAF2;

    .p-toast-message-icon,
    .p-toast-summary,
    .p-toast-detail,
    .p-toast-close-button {
      color: #4EBA75
    }
  }
}
</style>