<script>
import {mapActions, mapState} from "pinia";
import {managerStore} from "@/store/managerStore";
import {authStore} from "@/store/authStore";
import {baseLoaderStore} from "@/store/baseLoaderStore";
import InputText from "primevue/inputtext";
import InputNumber from 'primevue/inputnumber';
import BaseNoResultMessage from "@/components/BaseNoResultMessage.vue";
import {helpers, required} from "@vuelidate/validators";
import i18n from "@/i18n";
import {useVuelidate} from "@vuelidate/core";
export default {
  name: "ManagerCompanyInfoForm",
  setup () {
    return { v$: useVuelidate() }
  },
  props: {
    editMode: {
      type: Boolean
    },
    managerId: {
      type: String
    }
  },
  components: {
    InputText,
    InputNumber,
    BaseNoResultMessage
  },
  data() {
    return {
      dataIsLoadedState: false,
      companyInfoEditData: {
        companyName: ''
      },

      id: '',
      companyName: '',
      country: '',
      city: '',
      zipCode: '',
      address1: '',
      address2: '',
      vatNumber: '',
    }
  },
  validations () {
    return {
      companyName: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required)
      },
      country: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required)
      },
      city: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required)
      },
      zipCode: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required)
      },
      address1: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required)
      },
      vatNumber: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required)
      },
    }
  },
  computed: {
    ...mapState(authStore, [
      'managerProfileInfo',
      'managerCompanyInfo',
    ]),
    ...mapState(managerStore, [
      'managerCompanyInfo',
    ]),
    actualManagerId() {
      return this.managerId || this.managerProfileInfo.id;
    }
  },
  methods: {
    ...mapActions(baseLoaderStore, [
      'showBaseLoader',
      'hideBaseLoader'
    ]),
    ...mapActions(managerStore, [
      'createUpdateManagerCompanyInfo',
      'getManagerProfileInfo',
    ]),
    async saveInfo() {
      const isFormCorrect = await this.v$.$validate()

      this.v$.$touch();
      if (isFormCorrect) {
        this.showBaseLoader();
        this.createUpdateManagerCompanyInfo(this.actualManagerId, {
          id: this.id,
          companyName: this.companyName,
          country: this.country,
          city: this.city,
          zipCode: this.zipCode,
          address1: this.address1,
          address2: this.address2,
          vatNumber: this.vatNumber
        }).then(()=> {
          this.$emit('on-save');
          this.hideBaseLoader();
        });
      }
    }
  },
  beforeMount() {
    this.showBaseLoader();
    this.getManagerProfileInfo(this.actualManagerId).then(() => {
      if (this.editMode) {
        this.id = this.managerCompanyInfo.id;
        this.companyName = this.managerCompanyInfo.companyName;
        this.country = this.managerCompanyInfo.country;
        this.city = this.managerCompanyInfo.city;
        this.zipCode = Number(this.managerCompanyInfo.zipCode);
        this.address1 = this.managerCompanyInfo.address1;
        this.address2 = this.managerCompanyInfo.address2;
        this.vatNumber = this.managerCompanyInfo.vatNumber;
      }
      this.dataIsLoadedState = true;
      this.hideBaseLoader();
    }).catch(error => {
      if (error.status === 404) {
        this.dataIsLoadedState = true;
      }
      this.hideBaseLoader();
    })
  },
}
</script>

<template>
  <div v-if="dataIsLoadedState " class="company-info-form">
    <template v-if="!editMode && !managerCompanyInfo">
      <div class="w-100 d-flex justify-content-center">
        <base-no-result-message :text="'No company information'"/>
      </div>
    </template>
    <template v-else>
      <div class="company-info-form__col">
        <div class="d-flex flex-direction-column mb-15">
          <label class="font-bold mb-5">Company name:</label>
          <template v-if="editMode">
            <InputText v-model="companyName" class="form-control" :class="{'form-control--error': v$.companyName.$error}"/>
            <span v-for="error of v$.companyName.$errors" :key="error.$uid" class="form-control-error">
              {{ $t(error.$message) }}
            </span>
          </template>
          <template v-else>
            <span>{{managerCompanyInfo.companyName}}</span>
          </template>
        </div>
        <div class="d-flex flex-direction-column mb-15">
          <label class="font-bold mb-5">Country:</label>
          <template v-if="editMode">
            <InputText v-model="country" class="form-control"  :class="{'form-control--error': v$.country.$error}"/>
            <span v-for="error of v$.country.$errors" :key="error.$uid" class="form-control-error">
              {{ $t(error.$message) }}
            </span>
          </template>
          <template v-else>
            <span>{{managerCompanyInfo.country}}</span>
          </template>
        </div>
        <div class="d-flex flex-direction-column mb-15">
          <label class="font-bold mb-5">City:</label>
          <template v-if="editMode">
            <InputText v-model="city" class="form-control" :class="{'form-control--error': v$.city.$error}"/>
            <span v-for="error of v$.city.$errors" :key="error.$uid" class="form-control-error">
              {{ $t(error.$message) }}
            </span>
          </template>
          <template v-else>
            <span>{{managerCompanyInfo.city}}</span>
          </template>
        </div>
        <div class="d-flex flex-direction-column mb-15">
          <label class="font-bold mb-5">Zip code:</label>
          <template v-if="editMode">
            <InputNumber v-model="zipCode" class="form-control"  :class="{'form-control--error': v$.zipCode.$error}"/>
            <span v-for="error of v$.zipCode.$errors" :key="error.$uid" class="form-control-error">
              {{ $t(error.$message) }}
            </span>
          </template>
          <template v-else>
            <span>{{managerCompanyInfo.zipCode}}</span>
          </template>
        </div>
      </div>
      <div class="company-info-form__col">
        <div class="d-flex flex-direction-column mb-15">
          <label class="font-bold mb-5">Address 1:</label>
          <template v-if="editMode">
            <InputText v-model="address1" class="form-control" :class="{'form-control--error': v$.address1.$error}"/>
            <span v-for="error of v$.address1.$errors" :key="error.$uid" class="form-control-error">
              {{ $t(error.$message) }}
            </span>

          </template>
          <template v-else>
            <span>{{managerCompanyInfo.address1}}</span>
          </template>
        </div>
        <div v-if="address2" class="d-flex flex-direction-column mb-15">
          <label class="font-bold mb-5">Address 2:</label>
          <template v-if="editMode">
            <InputText v-model="address2" class="form-control"/>
          </template>
          <template v-else>
            <span>{{managerCompanyInfo.address2}}</span>
          </template>
        </div>
        <div class="d-flex flex-direction-column mb-15">
          <label class="font-bold mb-5">Vat number:</label>
          <template v-if="editMode">
            <InputText v-model="vatNumber" class="form-control" :class="{'form-control--error': v$.vatNumber.$error}"/>
            <span v-for="error of v$.vatNumber.$errors" :key="error.$uid" class="form-control-error">
              {{ $t(error.$message) }}
            </span>
          </template>
          <template v-else>
            <span>{{managerCompanyInfo.vatNumber}}</span>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>


<style lang="scss">
.company-info-form {
  display: flex;
  padding: 15px 0;
  border-radius: 10px;

  &__col {
    width: 310px;
  }
  &__item {

  }

  .p-inputtext {
    max-width: 275px;
    border-radius: 5px;
    border-color: #cbd5e1;
    &:focus {
      border-color: #020617 !important;
      outline: none !important;
    }
  }

  .form-control {
    &--error {
      border-color: #DC2626;
      .p-inputtext {
        border-color: #DC2626;
      }
    }
  }

  .form-control-error {
    font-size: 13px;
    margin-top: 5px;
    color: #DC2626;
  }
}
</style>