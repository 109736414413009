<script>
import TasksOverviewChart from "@/views/manager/ManagerTasksOverview/TasksOverviewChart.vue";
import TasksOverviewList from "@/views/manager/ManagerTasksOverview/TasksOverviewList.vue";
export default {
  name: "ManagerTasksOverview",
  props: {
    tasksStatistics: {
      type: Object
    },
    tasksCounts: {
      type: Object
    },
  },
  components: {
    TasksOverviewChart,
    TasksOverviewList
  },

}
</script>

<template>
<div class="tasks-overview-section">
  <div class="d-flex">
    <span class="tasks-overview-section__title">{{$t('managerLounge.tasksOverview')}}</span>
    <div class="d-flex ml-auto">
      <div class="d-flex ml-auto">
        <a target="_blank"
           href="/manager"
           class="go-to-ka-btn mr-10"
        >{{$t('managerLounge.managerView')}}</a>
        <a target="_blank"
           href="/managerKnowledgeArea"
           class="go-to-ka-btn"
        >{{$t('managerLounge.goToKnowledgeArea')}}</a>
      </div>
    </div>
  </div>

  <tasks-overview-chart v-if="tasksStatistics" :tasks-statistics="tasksStatistics"/>
  <tasks-overview-list v-if="tasksCounts" :task-counts="tasksCounts"/>
</div>
</template>

<style lang="scss">
.go-to-ka-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  padding: 0 8px;
  font-size: 14px;
  font-weight: 600;
  color: #7835a6;
  background: #f1e8f8;
  cursor: pointer;
  border: none;
}
</style>