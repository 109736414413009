<script>
import i18n from "@/i18n";
import {mapActions, mapState} from "pinia";
import {authStore} from "@/store/authStore";
import {baseLoaderStore} from "@/store/baseLoaderStore";
import {maintenanceStore} from "@/store/maintenanceStore";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseTab from "@/components/BaseTab.vue";
import ManagerViewOrg from "@/views/manager/managerView/ManagerViewOrg.vue";
import ManagerViewForum from "@/views/manager/managerView/ManagerViewForum.vue";
import ManagerViewElections from "@/views/manager/managerView/ManagerViewElections.vue";
import ManagerCriticalEvents from "@/views/manager/managerView/ManagerCriticalEvents.vue";
import ManagerContentReviews from "@/views/manager/managerView/ManagerContentReviews.vue";
import ManagerApplications from "@/views/manager/managerView/ManagerApplications.vue";
import ManagerIntegration from "@/views/manager/managerView/ManagerIntegration.vue";
import ManagerViewManagers from "@/views/manager/managerView/ManagerViewManagers.vue";
import ManagerUserTerms from "@/views/manager/managerView/ManagerUserTerms.vue";
import ManagerAds from "@/views/manager/managerView/ManagerAds.vue";
import ManagerRateLogModal from "@/views/manager/manager-modals/ManagerRateLogModal.vue";
import {managerStore} from "@/store/managerStore";

export default {
  name: "ManagerView",
  components: {
    BaseHeader,
    BaseTab,
    ManagerViewOrg,
    ManagerViewForum,
    ManagerViewElections,
    ManagerCriticalEvents,
    ManagerContentReviews,
    ManagerApplications,
    ManagerIntegration,
    ManagerViewManagers,
    ManagerUserTerms,
    ManagerAds,
    ManagerRateLogModal
  },
  data() {
    return {
      activeTab: {
        label: "",
        code: ""
      },
      viewClass: ''
    }
  },
  computed: {
    ...mapState(authStore, [
      'activeUserInfo',
      'notApproveOrgList',
      'managerApplicationsCount',
      'managerViewTotals'
    ]),
    ...mapState(managerStore,[
      'ratesLogModalState'
    ]),
    ...mapState(maintenanceStore, ['isResolveContentReviewBlocked']),
    hasHeaderMessage() {
      return this.isResolveContentReviewBlocked;
    },
    tabsTotals() {
      let totals = {
        applicationsCount: 0,
        archivedEventsCount: 0,
        contentReviewCount: 0,
        currentEventsCount: 0,
        currentOrgAppsCount: 0,
      }

      if (this.managerViewTotals) {
        totals = this.managerViewTotals;
      }

      return totals;
    },
    managerTabs() {
      return [
        { label: `${i18n.global.t('managerView.tabs.organizations')} (${this.tabsTotals.currentOrgAppsCount})`, code: 'MNG_ORG', name: 'ManagerViewOrg' },
        { label: i18n.global.t('managerView.tabs.forum'), code: 'MNG_FORUM', name: 'ManagerViewForum' },
        { label: i18n.global.t('managerView.tabs.elections'), code: 'MNG_ELECTIONS', name: 'ManagerViewElections' },
        { label: `${i18n.global.t('managerView.tabs.criticalEvents')} (${this.tabsTotals.currentEventsCount})`, code: 'MNG_CRITICAL_EVENT', name: 'ManagerCriticalEvents' },
        { label: `${i18n.global.t('managerView.tabs.contentReviews')} (${this.tabsTotals.contentReviewCount})`, code: 'MNG_CONTENT_REVIEWS', name: 'ManagerContentReviews' },
        { label: `${i18n.global.t('managerView.tabs.applications')} (${this.tabsTotals.applicationsCount})`, code: 'MNG_APPLICATIONS', name: 'ManagerApplications' },
        { label: i18n.global.t('managerView.tabs.managers'), code: 'MNG_MANAGERS', name: 'ManagerViewManagers' },
        { label: i18n.global.t('managerView.tabs.integration'), code: 'MNG_INTEGRATION', name: 'ManagerIntegration' },
        { label: i18n.global.t('managerView.tabs.userTerms'), code: 'MNG_USER_TERMS', name: 'ManagerUserTerms' },
        { label: i18n.global.t('managerView.tabs.ads'), code: 'MNG_ADS', name: 'ManagerAds' },
        { label: i18n.global.t('managerView.tabs.profile'), code: 'MNG_PROFILE', name: 'UserView' },
      ]
    },
    activeUserId() {
      return this.activeUserInfo ? this.activeUserInfo.id : null;
    },
    urlParams() {
      return new URLSearchParams(window.location.search);
    }
  },
  methods: {
    ...mapActions(authStore, [
      'getManagerViewTotals'
    ]),
    ...mapActions(baseLoaderStore, [
      'showBaseLoader',
      'hideBaseLoader'
    ]),
    setActiveTab(tab) {
      if (tab.code === this.activeTab.code) {
        return false;
      }

      if (tab.code === 'MNG_PROFILE') {
        this.$router.push({ name: 'UserView', params: { id: this.activeUserId }});
      } else {
        this.$router.push({ name: tab.name });
        this.showBaseLoader();
        this.activeTab = tab;
      }
    },
    calculateFrameHeight(paramHeight) {
      const frame = document.getElementById('managerViewFrame');

      if (frame) {
        const frameScrollHeight = document.getElementById('managerViewFrame').scrollHeight;
        this.viewClass = (paramHeight || 800) < frameScrollHeight  ? '' : 'manager-view-tabs--has-frame-scroll';
      }
    }
  },
  beforeMount() {
    const frameName = this.$route.matched[0].name;

    this.getManagerViewTotals();

    if (frameName === 'ManagerView') {
      this.activeTab = this.managerTabs[0];
    } else {
      this.activeTab = this.managerTabs.filter((tab) => {
        return frameName === tab.name;
      })[0];
    }
  },
  created() {
    window.addEventListener("resize", this.calculateFrameHeight);
    window.addEventListener("message", (event) => {
      if (event.data.setFrameContentHeight) {
        this.calculateFrameHeight(event.data.setFrameContentHeight);
      }
    });
  },
  unmounted() {
    window.removeEventListener("resize", this.calculateFrameHeight);
  },
}
</script>

<template>
  <base-header/>
  <div v-if="activeUserId"
       class="manager-view-content-wrap"
       :class="{'manager-view-content-wrap--has-header-message': hasHeaderMessage}"
  >
    <div class="manager-view-tabs"
         :class="viewClass"
    >
      <base-tab v-for="tab in managerTabs"
                :key="tab.code"
                :label="tab.label"
                :active="tab.code === activeTab.code"
                @click="setActiveTab(tab)"
      />
    </div>
    <div class="manager-view-content" :class="{'manager-view-content--has-message': hasHeaderMessage}">
      <template v-if="activeTab.code === 'MNG_ORG'">
        <manager-view-org :user-id="activeUserId"/>
      </template>
      <template v-else-if="activeTab.code === 'MNG_FORUM'">
        <manager-view-forum :user-id="activeUserId"/>
      </template>
      <template v-else-if="activeTab.code === 'MNG_ELECTIONS'">
        <manager-view-elections :user-id="activeUserId"/>
      </template>
      <template v-else-if="activeTab.code === 'MNG_CRITICAL_EVENT'">
        <manager-critical-events :user-id="activeUserId"/>
      </template>
      <template v-else-if="activeTab.code === 'MNG_CONTENT_REVIEWS'">
        <manager-content-reviews :user-id="activeUserId"
                                 :url-params="urlParams"
                                 :has-header-message="hasHeaderMessage"
        />
      </template>
      <template v-else-if="activeTab.code === 'MNG_APPLICATIONS'">
        <manager-applications :user-id="activeUserId"
                              :url-params="urlParams"
        />
      </template>
      <template v-else-if="activeTab.code === 'MNG_MANAGERS'">
        <manager-view-managers :user-id="activeUserId"/>
      </template>
      <template v-else-if="activeTab.code === 'MNG_INTEGRATION'">
        <manager-integration :user-id="activeUserId"/>
      </template>
      <template v-else-if="activeTab.code === 'MNG_USER_TERMS'">
        <manager-user-terms :user-id="activeUserId"/>
      </template>
      <template v-else-if="activeTab.code === 'MNG_ADS'">
        <manager-ads :user-id="activeUserId"/>
      </template>
    </div>

    <ManagerRateLogModal v-if="ratesLogModalState"/>
  </div>
</template>

<style lang="scss">
.base-content-wrap {
  background: #E9EAED;
}

.manager-view-content-wrap {
  width: 100%;
  padding-top: 79px;

  &--has-header-message {
    padding-top: 124px;
  }

  .manager-view-tabs {
    &:not(.manager-view-tabs--has-frame-scroll) {
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
    }
    position: relative;
    z-index: 1;
    width: 1220px;
    margin: 0 auto;
    display: flex;
    background: #fff;
    border-bottom: 1px solid #ccc;

    &:before {
      content: '';
      position: absolute;
      top: -20px;
      z-index: -1;
      width: 1220px;
      height: 20px;
      background: #E9EAED;
    }

    .base-tab {
      width: auto;
      padding: 0 8px;
    }

    &--has-frame-scroll {
      left: -10px;
    }
  }
  .manager-view-content {
    position: absolute;
    top: 70px;
    width: 100%;
    background: #E9EAED;
    &--has-message {
      top: 115px;

      .manager-view-frame {
        height: calc(100vh - 120px);
      }
    }
  }

  .manager-view-frame {
    width: 100%;
    height: calc(100vh - 75px);
  }
}
</style>