<script>
import moment from 'moment-timezone';
import {mapActions, mapState} from "pinia";
import {baseLoaderStore} from "@/store/baseLoaderStore";
import {managerStore} from "@/store/managerStore";
import {authStore} from "@/store/authStore";

export default {
  name: "BasisForInvoiceSection",
  props: {
    showInfo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      initialDate: '',
      selectedDate: '',

      currentYearNumber: new Date().getFullYear(),
      totalValue: '',
      totalTaskCount: '',
      tasks: []
    }
  },
  computed: {
    ...mapState(authStore, [
      'managerProfileInfo',
    ]),
    ...mapState(managerStore, [
      'managerBasisForInvoiceLoadParams'
    ]),
    managerId() {
      return this.managerBasisForInvoiceLoadParams ? this.managerBasisForInvoiceLoadParams.id : (this.managerProfileInfo ? this.managerProfileInfo.id : null);
    },
    isCurrentDate() {
      return this.initialDate === this.selectedDate;
    },
    showTasks() {
      return this.tasks.length > 0;
    },
    selectedDateTitle() {
      return moment(this.selectedDate).format('MMM YYYY')
    }
  },
  methods: {
    ...mapActions(baseLoaderStore, [
      'showBaseLoader',
      'hideBaseLoader'
    ]),
    ...mapActions(managerStore, [
      'getCompensationBasisInfoAction'
    ]),

    getCompensationData() {
      this.showBaseLoader();
      this.getCompensationBasisInfoAction(this.selectedDate, this.managerId).then((response)=> {
          this.totalValue = response.data.totalValues['USD'];
          this.totalTaskCount = response.data.totalTaskCount;
          this.tasks = response.data.tasks;
          this.hideBaseLoader();
      }).catch((error)=> {
        console.log(error);
        this.hideBaseLoader();
      });
    },
    nextPeriod() {
      this.selectedDate = moment(this.selectedDate).add(1, 'M').format('YYYY-MM');

      this.getCompensationData();
    },
    prevPeriod() {
      this.selectedDate = moment(this.selectedDate).subtract(1,'months').format('YYYY-MM');

      this.getCompensationData();
    },
    taskTitle(item) {
      return item.specialCase === 1 ? item.taskTitle + ' - special case 1' : item.taskTitle + ' - default';
    },
  },
  mounted: function () {
    this.initialDate = moment(new Date).format('YYYY-MM');
    this.selectedDate = this.initialDate;
    this.getCompensationData();
  }
}
</script>

<template>
  <div class="section invoice-section">
    <div v-if="showInfo" class="invoice-section__header">
      <p class="invoice-section__title">
        {{$t('basisForInvoice.title')}}
      </p>
      <p class="invoice-section__desc mb-10">
        {{$t('basisForInvoice.afterEachCalendar')}}
        <span class="text_semi-bold invoice-email">invoice@peoplepolitics.com</span>
      </p>
      <p class="invoice-section__desc">
        {{$t('basisForInvoice.ifYouDoNot.p1')}}
        <a href="https://www.frilansfinans.se" target="_blank" class="text_semi-bold">
          {{$t('basisForInvoice.ifYouDoNot.p2')}}
        </a>
        {{$t('basisForInvoice.ifYouDoNot.p3')}}
        <a href="https://coolcompany.com/se" target="_blank" class="text_semi-bold">
          {{$t('basisForInvoice.ifYouDoNot.p4')}}
        </a>
        {{$t('basisForInvoice.ifYouDoNot.p5')}}
      </p>
    </div>
    <div class="invoice-section__body">
      <div class="invoice-table-controls">
        <i class="fas fa-arrow-left invoice-table-controls__prev" @click="prevPeriod"></i>
        <span class="invoice-table-controls__text">{{selectedDateTitle}}</span>
        <i v-if="!isCurrentDate" class="fas fa-arrow-right invoice-table-controls__next" @click="nextPeriod"></i>
      </div>
      <div class="invoice-table-title">
        {{'$' + (totalValue || '0')}} - {{totalTaskCount || '0'}} {{$t('basisForInvoice.tasks')}}
      </div>
      <div class="invoice-table">
        <div class="invoice-table__header">
          <div class="invoice-table__col type">
            {{$t('basisForInvoice.taskType')}}
          </div>
          <div class="invoice-table__col number">
            {{$t('basisForInvoice.ofResolved')}}
          </div>
          <div class="invoice-table__col compensation">
            {{$t('basisForInvoice.compensationTask')}}
          </div>
          <div class="invoice-table__col subtotal">
            {{$t('basisForInvoice.subtotal')}}
          </div>
        </div>
        <template v-if="showTasks">
          <div v-for="item in tasks"
               class="invoice-table__row"
               :key="item.taskType + '_' + item.specialCase"
          >
            <div class="invoice-table__col type">
              {{taskTitle(item)}}
            </div>
            <div class="invoice-table__col number">
              {{item.taskCount}}
            </div>
            <div class="invoice-table__col compensation">
              {{'$' + item.rateValue}}
            </div>
            <div class="invoice-table__col subtotal">
              {{'$' + item.totalValue}}
            </div>
          </div>
          <div class="invoice-table__row invoice-table__row--total">
            <div class="invoice-table__col type">
              {{$t('basisForInvoice.total')}}
            </div>
            <div class="invoice-table__col number">
              {{totalTaskCount}}
            </div>
            <div class="invoice-table__col compensation">

            </div>
            <div class="invoice-table__col subtotal">
              {{'$' + totalValue}}
            </div>
          </div>
        </template>
        <template v-else>
              <span class="invoice-table__no-data">
                {{$t('basisForInvoice.noData')}}
              </span>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.invoice-section {
  padding: 15px 0 10px !important;
  &__header {
    margin: 16px;
    padding-bottom: 12px;
    border-bottom: 1px solid #f2f2f2;
  }
  &__title {
    display: block;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
  }
  &__desc {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #7b7b7b;
    text-align: left;
    line-height: 18px;
  }
  .invoice-email {
    color: #545454;
  }

  .invoice-table-controls {
    margin-bottom: 15px;
    padding: 0 15px;
    &__prev {
      font-size: 16px;
      color: #7835a6;
      cursor: pointer;
    }
    &__text {
      margin: 0 12px;
      font-size: 14px;
      font-weight: 600;
      color: #7835a6;
    }
    &__next {
      font-size: 16px;
      color: #7835a6;
      cursor: pointer;
    }
  }

  .invoice-table-title {
    margin-bottom: 15px;
    padding: 0 16px;
    font-size: 18px;
    font-weight: 600;
  }

  .invoice-table {
    display: flex;
    flex-direction: column;
    &__header {
      display: flex;
      align-items: center;
      height: 30px;
      background-color: #f2f2f2;
      .invoice-table__col {
        font-size: 12px;
        font-weight: 400;
      }
    }
    &__row {
      display: flex;
      width: 100%;
      height: 45px;
      background-color: #f2f2f2;

      &:nth-child(even) {
        background-color: #ffffff;
      }

      &--total {
        border-top: 1px solid #ccc;
      }
    }
    &__col {
      display: flex;
      align-items: center;
      padding: 0 13px;
      font-size: 13px;
      font-weight: 600;
      &.type {
        width: 415px
      }
      &.number {
        width: 178px
      }
      &.compensation {
        width: 230px
      }
      &.subtotal {
        width: 147px
      }
    }
    &__no-data {
      padding: 20px 20px 0;
    }

  }
}
</style>