
export class ProposalCreate {

    constructor(title, proposerId, creatorType, importanceType, issueNumber) {
        this.title = title;
        this.proposerId = proposerId;
        this.creatorType = creatorType; // CreatorType
        this.forum = 1; // TODO: valekseev: 1/20/2025: there is only forum now, id is hardcoded
        this.importanceType = importanceType; // ImportanceType
        this.issueNumber = issueNumber;
    }
}