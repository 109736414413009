<script>
import moment from "moment-timezone";
import {mapActions, mapState} from "pinia";
import {managerStore} from "@/store/managerStore";
import {baseLoaderStore} from "@/store/baseLoaderStore";
import ManagerCompensationForm from "@/views/manager/managerView/ManagerTabView/ManagerCompensationForm.vue";
import ManagerRatesItem from "@/views/manager/managerView/ManagerTabView/ManagerRatesItem.vue";

export default {
  name: "ManagerRates",
  components: {
    ManagerCompensationForm,
    ManagerRatesItem,
  },
  data() {
    return {
      onEditState: false,
      taskAverageList: null
    }
  },
  computed: {
    ...mapState(managerStore,[
      'ratesList'
    ]),
  },
  methods: {
    ...mapActions(baseLoaderStore, [
      'showBaseLoader',
      'hideBaseLoader'
    ]),
    ...mapActions(managerStore, [
      'getRatesInfoAction',
      'getTaskAverageAction'
    ]),
    onEdit () {
      this.onEditState = true;
    },
    cancelEdit() {
      this.onEditState = false;
    },
    applyNewCompensationRate() {
      let rateItems = [];

      this.$refs.ratesItem.forEach((item)=> {
        rateItems.push(item.updateRateAction(false));
      })

      Promise.all(rateItems).then(() => {
        this.getRatesInfoAction().then(()=> {
          this.hideBaseLoader();
        });
      })
    }
  },
  beforeMount() {
    this.showBaseLoader();
    this.getRatesInfoAction().then(()=> {
      this.hideBaseLoader();
    });

    const currentMonth = moment(new Date).format('YYYY-MM');
    this.getTaskAverageAction(currentMonth).then((resp)=> {
      this.taskAverageList = resp.data;
    })
  }
}
</script>

<template>
<div class="manager-rates-table">
  <manager-compensation-form @on-change-compensation="applyNewCompensationRate()"/>
  <div class="manager-rates-table-header">
    <div class="manager-rates-table__row">
      <div class="manager-rates-table__col task-type">
        {{$t('editRates.taskType')}}
      </div>
      <div class="manager-rates-table__col expected-time">
        {{$t('editRates.expectedTime')}}
      </div>
      <div class="manager-rates-table__col spend-time">
        {{$t('editRates.spendTime')}}
      </div>
      <div class="manager-rates-table__col current-compensation">
        {{$t('editRates.currentCompensation')}} (SEK)
      </div>
      <div class="manager-rates-table__col upcoming-change">
        {{$t('editRates.upcomingChange')}}
      </div>
      <div class="manager-rates-table__col rate-log">
        {{$t('editRates.rateLog')}}
      </div>
      <div class="manager-rates-table__col action">
        {{$t('editRates.action')}}
      </div>
    </div>
  </div>
  <div class="manager-rates-table__body">
    <manager-rates-item v-for="item in ratesList"
                        :key="item.type"
                        :item-data="item"
                        :average-time="taskAverageList[item.type]"
                        :disable-edit="onEditState"
                        ref="ratesItem"
                        @on-update="getRatesInfoAction()"
                        @on-edit="onEdit()"
                        @on-cancel-edit="cancelEdit()"
    />
  </div>
</div>
</template>

<style lang="scss">
.manager-rates-table {
  margin-bottom: 30px;
  .manager-rates-table-header {
    .manager-rates-table__col {
      font-size: 12px;
      font-weight: 600;
      background: #7835A6;
      color: #fff;
    }
  }
  &__row-wrap {
    &.active {
      margin: 0 -10px;
      padding: 10px;
      background: #f2f2f2 !important;
      border-radius: 5px;
      border: 1px solid #7835a6;
    }
  }
  &__row {
    display: flex;
    align-items: center;
    height: 40px;

    &.edit {
      width: 100%;
      height: auto;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 15px;
      background: #efe4f3;
      border-color: #a163cc;
      border-radius: 5px;
      box-sizing: border-box;
    }
  }

  &__col {
    display: flex;
    align-items: center;
    flex: 1;
    height: calc(100% - 2px);
    padding: 5px;
    margin: 1px;
    font-size: 12px;
    background: #E7E9EC;

    &.task-type {
      min-width: 350px
    }

    &.compensation-rate {
      min-width: 200px
    }

    &.data-range {
      min-width: 120px;
      max-width: 120px;
    }

    &.update-date {
      min-width: 170px;
    }

    &.rate-log {
      justify-content: center;
    }

    &.action {
      justify-content: center;
    }
  }

  .change-rate-control {
    &--disabled {
      cursor: default;
      color: #bbb;
    }
  }
}

</style>