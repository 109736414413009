import {formatZonedDateTime} from "@/baseUtils";
import {Content} from "@/store/model/Content";
import {ImgLabel} from "@/store/model/ImgLabel";


export class ProposalOfficialStatus {

    static DRAFT = "DRAFT";
    static TO_BE_RAISED = "TO_BE_RAISED";
    static REGISTERED = "REGISTERED";
    static APPROVED = "APPROVED";
    static REFERRED = "REFERRED";
    static ARCHIVED = "ARCHIVED";
    static NOT_APPROVED = "NOT_APPROVED";
    static EXPIRED = "EXPIRED";
    static REVOKED = "REVOKED";
    static TO_BE_DECIDED = "TO_BE_DECIDED";
    static IMPLEMENTED = "IMPLEMENTED";
    static APPROVAL = "APPROVAL";
    static PARTIAL_APPROVAL = "PARTIAL_APPROVAL";
    static REJECTED = "REJECTED";
}

export class ExternalProposalType {

    static PROPOSAL = "PROPOSAL";
    static INDEPENDENT_MOTION = "INDEPENDENT_MOTION";
    static COMMITTEE_REPORT = "COMMITTEE_REPORT";
    static COUNTER_MOTION = "COUNTER_MOTION";
}

export class CommitteeType {

    static LABOR_MARKET = "LABOR_MARKET";
    static CIVIL_AFFAIRS = "CIVIL_AFFAIRS";
    static FINANCE = "FINANCE";
    static DEFENCE = "DEFENCE";
    static JUSTICE = "JUSTICE";
    static CONSTITUTION = "CONSTITUTION";
    static CULTURE = "CULTURE";
    static ENVIRONMENT = "ENVIRONMENT";
    static INDUSTRY = "INDUSTRY";
    static TAXES = "TAXES";
    static INSURANCE = "INSURANCE";
    static HEALTH = "HEALTH";
    static COMMUNICATIONS = "COMMUNICATIONS";
    static EDUCATION = "EDUCATION";
    static FOREIGN_AFFAIRS = "FOREIGN_AFFAIRS";
    static DEFENSE = "DEFENSE";
}

export class ContentStatus {

    static MISSING = "MISSING";
    static PROCESSING = "PROCESSING";
    static PROCESSED = "PROCESSED";
}

export class BaseExtraProposalAttributes {

    constructor(data) {
        this.status = data.status; // ProposalOfficialStatus
        this.statusDate = formatZonedDateTime(data.statusDate);
    }
}

export class RiksdagenExtraProposalAttributes extends BaseExtraProposalAttributes{

    constructor(data) {
        super(data);
        this.docId = data.docId;
        this.externalProposalType = data.externalProposalType; // ExternalProposalType
        this.committeeType = data.committeeType; // CommitteeType
        this.contentStatus = data.contentStatus; // ContentStatus
        this.committeeDecisionDates = data.committeeDecisionDates;
        this.mainSource = data.mainSource ? new Content(data.mainSource) : null;
        this.spokes = data.spokes.map(s => new ImgLabel(s));
        this.committeeDecisionDate = formatZonedDateTime(data.committeeDecisionDate);
        this.riksdagenDecisionDate = formatZonedDateTime(data.riksdagenDecisionDate);
    }
}