<script>
import Dialog from "primevue/dialog";
import ManagerCompanyInfoForm from "@/views/manager/ManagerCompanyInfoForm.vue";
export default {
  name: "ManagerCompanyInfoModal",
  props: {
    companyInfoModalState: {
      type: Boolean
    },
    managerId: {
      type: Number,
    },
    editModeState: {
      type: Boolean
    }
  },
  components: {
    Dialog,
    ManagerCompanyInfoForm
  },
  computed: {
    modalState: {
      get() {
        return this.companyInfoModalState;
      },
      set() {
        this.closeModal();
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit('on-close');
    },
    saveCompanyInfo() {
      const companyInfoForm = this.$refs.managerCompanyInfoForm;

      if (companyInfoForm) {
        companyInfoForm.saveInfo();
      }
    }
  }
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          modal
          :header="'Manager company information'"
          class="manager-company-information-modal"
  >
    <div>
      <manager-company-info-form :edit-mode="editModeState" :manager-id="managerId"
                                 ref="managerCompanyInfoForm"
                                 @on-save="closeModal()"
      />
    </div>
    <template #footer>
      <button v-if="editModeState" type="button" class="p-dialog-primary-btn" @click="saveCompanyInfo()">
        {{$t('common.buttons.save')}}
      </button>
      <button type="button" class="p-dialog-close-btn" @click="closeModal()">
        {{$t('common.buttons.cancel')}}
      </button>
    </template>
  </Dialog>
</template>

<style lang="scss">
.manager-company-information-modal {
  min-width: 680px;
  .p-dialog-content {
    min-height: 365px;
    padding: 15px;
  }
}
</style>