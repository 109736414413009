<script>
import i18n from "@/i18n";
import ManagerList from "@/views/manager/managerView/ManagerTabView/ManagerList.vue";
import ManagerJobs from "@/views/manager/managerView/ManagerTabView/ManagerJobsList.vue";
import ManagerRates from "@/views/manager/managerView/ManagerTabView/ManagerRates.vue";

export default {
  name: "ManagerViewManagers",
  props: {
    userId: {
      type: Number
    }
  },
  components: {
    ManagerList,
    ManagerJobs,
    ManagerRates
  },
  data() {
    return {
      activeTab: {
        code: '',
        title: ''
      }
    }
  },
  computed: {
    tabs() {
      return [
        {
          code: 'LIST_MANAGER',
          title: i18n.global.t('managerView.manager.tabs.listManagers')
        },
        {
          code: 'JOB_OPENiNGS',
          title: i18n.global.t('managerView.manager.tabs.jobOpenings')
        },
        {
          code: 'EDIT_RATES',
          title: i18n.global.t('managerView.manager.tabs.editRates')
        },
        // {
        //   code: 'ADD_MANAGER',
        //   title: i18n.global.t('managerView.manager.tabs.addManager')
        // }
      ]
    },
    frameUrl() {
      return `/${this.userId}/mydesktop?tab=managers&_f=true`
    }
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    }
  },
  mounted() {
    this.activeTab = this.tabs[0];
  }
}
</script>

<template>
  <div class="managers-view">
    <ul class="managers-view-tabs">
      <li v-for="item in tabs"
          :key="item.code"
          class="managers-view-tab"
          :class="{'active': item.code === activeTab.code}"
          @click="setActiveTab(item)"
      >{{ item.title }}</li>
    </ul>
    <div class="managers-view-content">
      <manager-list v-if="activeTab.code === 'LIST_MANAGER'"/>
      <manager-jobs v-else-if="activeTab.code === 'JOB_OPENiNGS'"/>
      <manager-rates v-else-if="activeTab.code === 'EDIT_RATES'"/>
    </div>
  </div>
</template>

<style lang="scss">
.managers-view {
  max-width: 1220px;
  margin: 0 auto;
  background: #FFFFFF;
}

.managers-view-tabs {
  display: flex;
  width: 1217px;
  margin: 50px auto 0;
  padding: 10px 0 10px;
  background: #fff;

  .managers-view-tab {
    position: relative;
    padding: 0 15px;
    cursor: pointer;
    color: #7835a6;

    &:before {
      content: '\2022';
      font-size: 12px;
      color: #7835a6;
      position: absolute;
      left: 0;
      top: 1px;
    }

    &:first-child {
      &:before {
        content: none;
      }
    }

    &.active {
      color: #000000;
    }
  }
}

.managers-view-content {
  width: 1217px;
  margin: 0 auto;
  padding: 9px 16px 16px;
  background: #fff;
}
</style>