<script>
import {formatZonedDateTime} from '@/baseUtils';
import {mapActions, mapState} from "pinia";
import {managerStore} from "@/store/managerStore";
import Dialog from "primevue/dialog";
import {baseLoaderStore} from "@/store/baseLoaderStore";

export default {
  name: "ManagerRateLogModal",
  components: {
    Dialog
  },
  data() {
    return {
      rateHistoryData: null
    }
  },
  computed: {
    ...mapState(managerStore, [
      'ratesLogModalState',
      'ratesLogLoadParams'
    ]),
    modalState: {
      get() {
        return this.ratesLogModalState;
      },
      set() {
        this.closeModal();
      },
    },
    specialCaseVersion() {
      return this.ratesLogLoadParams.specialCase;
    }
  },
  methods : {
    ...mapActions(baseLoaderStore, [
      'showBaseLoader',
      'hideBaseLoader'
    ]),
    ...mapActions(managerStore, [
      'getRateVersionsAction',
      'toggleRatesLogModalState'
    ]),
    closeModal() {
      this.toggleRatesLogModalState();
    },
    changeRateDate(date) {
      return formatZonedDateTime(date);
    },
    processingRateHistoryData() {
      this.rateHistoryData.forEach((item, i, arr)=> {
        let currentVal = arr[i].values[this.specialCaseVersion];
        let prevVal = arr[i + 1] ? arr[i + 1].values[this.specialCaseVersion] : null;

        if (currentVal && prevVal) {
          currentVal.compensationBasedUponChanged = currentVal.compensationBasedUpon !== prevVal.compensationBasedUpon;
          currentVal.socialTaxRateChanged = currentVal.socialTaxRate !== prevVal.socialTaxRate;
          currentVal.vacationPayChanged = currentVal.vacationPay !== prevVal.vacationPay;
          currentVal.expectedTimeSecondsChanged = currentVal.expectedTimeSeconds !== prevVal.expectedTimeSeconds;
          currentVal.valueChanged = currentVal.value !== prevVal.value;
        }
      })
    }
  },
  mounted() {
    this.showBaseLoader();
    this.getRateVersionsAction(this.ratesLogLoadParams).then((resp)=> {
      this.rateHistoryData = resp.data;
      this.processingRateHistoryData();
      this.hideBaseLoader();
    });
  }
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          class="manager-rate-log-modal"
          modal
          :header="`${ratesLogLoadParams.taskTitle} - ${$t('editRates.allRates')}` "
  >
    <div class="rate-log-table">
      <div class="rate-log-table__header">
        <div class="rate-log-row">
          <div class="rate-log-col rate-log-col--base-upon">{{$t('editRates.compensationBasedUpon')}} (SEK)</div>
          <div class="rate-log-col rate-log-col--tax">{{$t('editRates.socialTaxRate')}}</div>
          <div class="rate-log-col rate-log-col--tax">{{$t('editRates.vacationPay')}}</div>
          <div class="rate-log-col">{{$t('editRates.expectedTime')}}</div>
          <div class="rate-log-col">{{$t('editRates.compensation')}} (SEK)</div>
          <div class="rate-log-col rate-log-col--date">{{$t('editRates.startDate')}}</div>
          <div class="rate-log-col rate-log-col--date">{{$t('editRates.endDate')}}</div>
          <div class="rate-log-col rate-log-col--change-date">{{$t('editRates.changeRateDate')}}</div>
          <div class="rate-log-col">{{$t('editRates.enteredBy')}}</div>
        </div>
      </div>
      <div class="rate-log-table__body">
        <div v-for="item in rateHistoryData"
             :key="item.type"
             class="rate-log-row"
        >
          <div class="rate-log-col rate-log-col--base-upon"
               :class="{'rate-log-col--changed': item.values[specialCaseVersion].compensationBasedUpon}"
          >
            {{item.values[specialCaseVersion].compensationBasedUpon || '--'}}
          </div>
          <div class="rate-log-col rate-log-col--tax"
               :class="{'rate-log-col--changed': item.values[specialCaseVersion].socialTaxRateChanged}"
          >
            {{item.values[specialCaseVersion].socialTaxRate || '--'}}
          </div>
          <div class="rate-log-col rate-log-col--tax"
               :class="{'rate-log-col--changed': item.values[specialCaseVersion].vacationPayChanged}"
          >
            {{item.values[specialCaseVersion].vacationPay || '--'}}
          </div>
          <div class="rate-log-col"
               :class="{'rate-log-col--changed': item.values[specialCaseVersion].expectedTimeSecondsChanged}"
          >
            {{item.values[specialCaseVersion].expectedTimeSeconds || '--'}}
          </div>
          <div class="rate-log-col"
               :class="{'rate-log-col--changed': item.values[specialCaseVersion].valueChanged}"
          >
            {{item.values[specialCaseVersion].value.toFixed(2)}}
          </div>
          <div class="rate-log-col rate-log-col--date">
            {{item.dateStart || '--'}}
          </div>
          <div class="rate-log-col rate-log-col--date">
            {{item.dateEnd || '--'}}
          </div>
          <div class="rate-log-col rate-log-col--change-date">
            {{changeRateDate(item.updatedDateTime)}}
          </div>
          <div class="rate-log-col">
            {{item.editorFullName}}
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <button type="button" class="p-dialog-close-btn" @click="closeModal()">
        {{$t('common.buttons.close')}}
      </button>
    </template>
  </Dialog>
</template>

<style lang="scss">
.manager-rate-log-modal {
  width: 960px;

  .p-dialog-content {
    padding: 0;
  }

  .rate-log-table {
    &__header {
      .rate-log-row {
        .rate-log-col {
          font-size: 12px;
          font-weight: 600;
          background: #CAD1D8;
        }
      }
    }

    .rate-log-row {
      display: flex;
    }

    .rate-log-col {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 40px;
      padding: 5px;
      margin: 1px;
      font-size: 12px;
      line-height: 14px;
      background: #E7E9EC;
      &--base-upon {
        min-width: 115px;
      }
      &--tax {
        max-width: 80px;
      }
      &--date {
        max-width: 80px;
      }
      &--change-date {
        min-width: 150px;
      }
      &--changed {
        background: #FFC000;
      }
    }
  }
}
</style>