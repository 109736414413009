export class CompanyInformation {
    constructor(data) {
        this.id = data.id;
        this.status = data.status;
        this.companyName = data.companyName;
        this.vatNumber = data.vatNumber;
        this.country = data.country;
        this.city = data.city;
        this.address1 = data.address1;
        this.address2 = data.address2;
        this.zipCode = data.zipCode;
    }
}