<script>
import {mapActions, mapState} from "pinia";
import {baseLoaderStore} from "@/store/baseLoaderStore";
import {proposalStore} from "@/store/proposalStore";
import {authStore} from "@/store/authStore";
import EditProposalGeneralInfo from "@/views/proposal/proposal-edit/EditProposalGeneralInfo.vue";
import EditProposalEnterActual from "@/views/proposal/proposal-edit/EditProposalEnterActual.vue";
import EditProposalArguments from "@/views/proposal/proposal-edit/EditProposalArguments.vue";
// import EditProposalSubjects from "@/views/proposal/proposal-edit/EditProposalSubjects.vue";
import EditProposalImportance from "@/views/proposal/proposal-edit/EditProposalImportance.vue";
// import EditProposalThumbnail from "@/views/proposal/proposal-edit/EditProposalThumbnail.vue";
import ActualProposalExamplesModal from "@/views/proposal/proposal-edit/ActualProposalExamplesModal.vue";

export default {
  name: "EditProposalView",
  components: {
    EditProposalGeneralInfo,
    EditProposalEnterActual,
    EditProposalArguments,
    // EditProposalSubjects,
    EditProposalImportance,
    // EditProposalThumbnail,
    ActualProposalExamplesModal
  },
  computed: {
    ...mapState(authStore, ['activeUserInfo']),
    ...mapState(proposalStore, [
      'editedProposalData',
      'actualExamplesModalState',
      'closeCreatePropFormView',
      'resetStoreData',
      'setActualProposalText',
      'isDraftLoaded'
    ]),
  },
  methods: {
    ...mapActions(baseLoaderStore, [
      'showBaseLoader',
      'hideBaseLoader'
    ]),
    ...mapActions(proposalStore, [
      'setProposalArguments',
      'setEditPropValue',
      'processProposalSaving'
    ]),
    isValidToSave(proposalStatus) {
      let state = false;
      const $editProposalGeneralInfo = this.$refs.editProposalGeneralInfo;
      const $editProposalEnterActual = this.$refs.editProposalEnterActual;
      const $editProposalArguments = this.$refs.editProposalArguments;

      if (proposalStatus === 'DRAFT') {
        $editProposalGeneralInfo.v$.$touch();

        state = !$editProposalGeneralInfo.v$.$invalid;
      } else if (proposalStatus === 'PUBLISH') {
        $editProposalGeneralInfo.v$.$touch();
        $editProposalEnterActual.v$.$touch();
        $editProposalArguments.validateArguments();

        state = (!$editProposalGeneralInfo.v$.$invalid &&
            !$editProposalEnterActual.v$.$invalid &&
            $editProposalArguments.readyForSave);
      }

      return state;
    },
    saveProposal(actionType) {
      if (this.isValidToSave(actionType)) {
        this.processProposalSaving(actionType);
      }
    }
  },
  beforeUnmount() {
    this.resetStoreData();
    this.closeCreatePropFormView();
    this.setActualProposalText('');
  }
}
</script>

<template>
  <div class="edit-proposal-view-wrap">
    <div v-if="isDraftLoaded" class="edit-proposal-view">
      <edit-proposal-general-info ref="editProposalGeneralInfo"/>
      <edit-proposal-enter-actual ref="editProposalEnterActual"/>
      <edit-proposal-arguments ref="editProposalArguments"/>
<!--      <edit-proposal-subjects-->
<!--          :subjects-list="editedProposalData.subjects"-->
<!--      />-->
      <edit-proposal-importance/>
<!--      <edit-proposal-thumbnail/>-->
    </div>
    <actual-proposal-examples-modal :dialog-state="actualExamplesModalState"/>
  </div>
</template>

<style lang="scss">
.edit-proposal-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 150px;
  background: #E9EAED;
}

.edit-prop-section {
  width: 970px;
  margin-bottom: 10px;
  padding: 15px;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0 2px 8px 0 rgba(79, 79, 79, 0.15);

  &__title {
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: 600;
    color: #4f4f4f
  }
}

</style>