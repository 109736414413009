import {defineStore, mapActions} from 'pinia';
import axios from "axios";
import {notificationStore} from "@/store/notificationStore";

export const authStore = defineStore({
    id: 'authStore',
    state: () => ({
        userInfo: null,
        managerProfileInfo: null,
        userIsActiveManager: false,
        userApplications: [],
        activeUserInfo: null,
        userActivated: true,
        acceptedCookie: true,
        approveOrgList: [],
        notApproveOrgList: [],
        needVerifyUserModalState: false,

        // Auth modal
        loginModalState: false,

        // Sign up modal
        signUpModalState: false,
        signUpCustomCallback: null,
        signUpErrorCallback: null,

        emailExistModalState: false,

        // Reset password modal
        resetPasswordModalState: false,
        resetPasswordModalCloseCallback: null,

        // Change email modal
        changeEmailModalState: false,

        // Create org
        createOrgState: false,
        registerPartyInfoState: false,

        // Leave page confirm modal
        leavePageModal: false,
        hasUnsavedChanges: true,
        unsavedChangesCallback: null,

        //Manager
        managerViewTotals: null,
        managerApplicationsCount: 0
    }),
    getters: {
        hasHeaderMessage() {
            return !this.userActivated || !this.acceptedCookie;
        },
        userLoggedIn() {
            return this.userInfo !== null;
        },
        userIsManager() {
            return this.managerProfileInfo !== null;
        },
        showCookiePolicyMessage() {
            return !this.acceptedCookie && !this.userLoggedIn;
        },
        userTimeZoneId() {
            if (this.userInfo !== null) {
                return this.userInfo.tmzId ? this.userInfo.tmzId : 'Europe/Stockholm';
            }
            return 'Europe/Stockholm';
        },
        approveOrgCount() {
            return this.approveOrgList.length;
        }
    },
    actions: {
        ...mapActions(notificationStore, [
            'getUnreadNewsCountAction',
            'getCriticalEventCountAction',
            'cleanNotificationStore'
        ]),

        // Auth modal
        loginAction(formData) {
            const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }};
            return axios.post('/api/login', formData, config);
        },
        logoutAction() {
            return axios.post('/api/logout')
                .then(() => {
                    this.userInfo = null;
                    this.managerProfileInfo = null;
                    this.userActivated = true;
                    this.cleanNotificationStore();
                })
                .catch(error => console.log(error));
        },
        authGetUserData() {
            return axios.get('/api/user/desktop')
                .then((res) => {
                    this.userInfo = res.data;
                    this.setActiveUserAction(res.data);
                    this.checkUserActive();
                })
                .catch(error => console.log(error));
        },
        getManagerProfile() {
            return axios.get('/api/manager/profile')
                .then((resp)=> {
                    this.managerProfileInfo = resp.data;
                    if (this.managerProfileInfo) {
                        this.userIsActiveManager = this.managerProfileInfo.status === 'ACTIVE';
                    }
                }).catch(error => console.log(error));
        },
        getApprovedOrganizations() {
            axios.get('/api/user/desktop/organizations/approved')
                .then((res) => {
                    this.approveOrgList = res.data;
                })
                .catch(error => console.log(error));
        },
        getCurrentOrgApplications() {
            axios.get('/currentorgapplications?page.page=1&page.size=20')
                .then((res) => {
                    this.notApproveOrgList = res.data.content;
                })
        },
        checkUserActive() {
            axios.get('/api/user/desktop/pendingemail')
                .then((res) => {
                    this.userActivated = !res.data;
                })
                .catch(error => console.log(error));
        },
        setActiveUserAction(userInfo) {
            this.activeUserInfo = userInfo;
        },
        setCookieAcceptedAction() {
            localStorage.setItem('coockieTerms:accepted', true);
        },
        checkCookieAcceptedAction() {
            this.acceptedCookie = localStorage.getItem('coockieTerms:accepted', true) === 'true';
        },
        toggleLoginModalState() {
            this.loginModalState = !this.loginModalState;
        },

        // Sign up modal
        openSignUpModal(signUpCallback) {
            this.signUpModalState = true;

            if (signUpCallback) {
                this.signUpCustomCallback = signUpCallback;
            }
        },
        closeSignUpModal() {
            this.signUpModalState = false;
        },
        setSignUpErrorCallback(errorCallback) {
            if (errorCallback) {
                this.signUpErrorCallback = errorCallback;
            }
        },
        signUpRequest(formData) {
            return axios.post('/api/registration/join', formData)
                .then(() => {
                    if (this.signUpCustomCallback) {
                        this.signUpCustomCallback();
                        this.signUpCustomCallback = null;
                    } else {
                        window.parent.window.location.href='/registrationwizard';
                    }
                }).catch((error) => {
                    if (this.signUpErrorCallback) {
                        this.signUpErrorCallback(error);
                        this.signUpErrorCallback = null;
                    }
                });
        },
        toggleEmailExistModalState() {
            this.emailExistModalState = !this.emailExistModalState;
        },

        // User applications
        getUserApplications() {
            return axios.get('/api/user/desktop/applications?type=BECOME_MANAGER').then((resp) => {
                this.userApplications = resp.data.content;
            });
        },

        //Manager
        getManagerViewTotals() {
            axios.get('/api/user/desktop/totals').then((resp)=> {
                this.managerViewTotals = resp.data;
            })
        },
        getManagerActiveApplicationsCount() {
            axios.get('/api/manager/application/counts').then((resp)=> {
                this.managerApplicationsCount = resp.data.total.ACTIVE || 0;
            })
        },
        sendApplicationAction(data) {
            return axios.post('/api/manager/application', data);
        },


        // Reset password modal
        openResetPasswordModal(closeCallback) {
            this.resetPasswordModalState = true;

            if (closeCallback) {
                this.resetPasswordModalCloseCallback = closeCallback;
            }
        },
        closeResetPasswordModal() {
            this.resetPasswordModalState = false;
            if (this.resetPasswordModalCloseCallback) {
                this.resetPasswordModalCloseCallback();
                this.resetPasswordModalCloseCallback = null;
            }
        },
        sendPasswordAction(email) {
            return axios.post(`/api/auth/sendpassword?email=${email}`)
                .then(() => {
                    this.resetPasswordModalCloseCallback = null;
                    this.closeResetPasswordModal();
                });
        },

        // Change email modal
        toggleChangeEmailModalState() {
            this.changeEmailModalState = !this.changeEmailModalState;
        },
        resendRegistrationEmail() {
            axios.post('/api/user/desktop/resendregistrationemail')
                .catch(error => console.log(error));
        },
        changeEmailRequest(newEmail) {
            return axios.post(`/api/user/desktop/updateprimaryemail?email=${newEmail}`)
                .then(() => {
                    this.toggleChangeEmailModalState();
                    this.authGetUserData();
                })
                .catch(error => console.log(error));
        },
        checkUserIsApproved() {
            let state = true;

            if (!this.userActivated) {
                this.toggleNeedVerifyUserModal();
                state = false;
            }

            return state;
        },
        toggleNeedVerifyUserModal() {
            return this.needVerifyUserModalState = !this.needVerifyUserModalState;
        },

        //Create org
        toggleCreateOrgModal() {
            this.createOrgState = !this.createOrgState;
        },
        toggleRegisterPartyInfoModal() {
            this.registerPartyInfoState = !this.registerPartyInfoState;
        },

        // Leave page confirm modal
        setUnsavedChanges(state, callback) {
            this.hasUnsavedChanges = state;

            if (callback) {
                this.unsavedChangesCallback = callback;
            }
        },
        toggleLeavePageModal() {
            this.leavePageModal = !this.leavePageModal;
        },
    }
});