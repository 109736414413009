import {formatZonedDateTime} from "@/baseUtils";
export class CompensationRate {
    constructor(data) {
        this.createdDateTime = formatZonedDateTime(data.createdDateTime);
        this.creatorAvatarHash = data.creatorAvatarHash;
        this.creatorFullName = data.creatorFullName;
        this.creatorUserId = data.creatorUserId;
        this.dateEnd = data.dateEnd;
        this.dateStart = data.dateStart;
        this.editorAvatarHash = data.editorAvatarHash;
        this.editorFullName = data.editorFullName;
        this.editorUserId = data.editorUserId;
        this.nextTaskRate = data.nextTaskRate;
        this.specialCaseSupported = data.specialCaseSupported;
        this.type = data.type;
        this.updatedDateTime = formatZonedDateTime(data.updatedDateTime);
        this.values = data.values;
    }
}