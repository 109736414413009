import {ZonedDateTime, DateTimeFormatter, ZoneId} from '@js-joda/core';
import '@js-joda/timezone';

const FORMATTER = DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm');

export function validURL(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

export function onlyNumberKey (evt) {
    // Only ASCII character in that range allowed
    const ASCIICode = (evt.which) ? evt.which : evt.keyCode;
    return !(ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57));
}

export function tagsValidations (str) {
    const reg =/<(.|\n)*?>/g;
    return reg.test(str) === true && str.length > 0;
}

export function iOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

/**
 * Formats input zoned-based date time string to instant date time string depends on PP user settings
 *
 * @param zdtStr
 * @returns {string}
 */
export function formatZonedDateTime (zdtStr) {
    if (zdtStr) {
        const userTimeZoneId = window.parent.mainApp.userTimeZoneId;
        const userTz = ZoneId.of(userTimeZoneId);
        const zdt = ZonedDateTime.parse(zdtStr);
        return userTz.id() === zdt.zone().id() ? zdt.format(FORMATTER) : zdt.withZoneSameInstant(userTz).format(FORMATTER);
    }
    return '';
}
