<script>
import { VueEcharts } from 'vue3-echarts';
import i18n from "@/i18n";
export default {
  name: "TasksOverviewChart",
  props: {
    tasksStatistics: {
      type: Object,
      required: true
    }
  },
  components: {
    VueEcharts,
  },
  computed: {
    polar() {
      return {
        series: [
          {
            type: "pie",
            animation: false,
            radius: "75%",
            center: ["50%", "50%"],
            data: [
              {
                value: this.tasksStatistics.PERMITTED,
                name: i18n.global.t('managerLounge.availableTasksForYou') + ' (' + this.tasksStatistics.PERMITTED + ')'
              },
              {
                value: this.tasksStatistics.CAN_APPLY,
                name: i18n.global.t('managerLounge.tasksYouCanApplyFor') + ' (' + this.tasksStatistics.CAN_APPLY + ')'
              },
              {
                value: this.tasksStatistics.CLOSED,
                name: i18n.global.t('managerLounge.tasksCurrentlyClosedForYou') + ' (' + this.tasksStatistics.CLOSED + ')'
              },
            ],
            color: [
              '#41D59F',
              '#51CCFE',
              '#CCCCCC'
            ],
            label: {
              fontSize: 16,
              color: '#525152',
              fontFamily: 'Open Sans',
              fontWeight: '600',
            },
            labelLine: {
              lineStyle: {
                type: 'dashed',
                width: 2,
                color: '#979797'
              }
            },

          }
        ]
      }
    }
  },
  mounted() {
    this.$refs.chart.dispatchAction({
      type: 'hideTip'
    });
    this.$refs.chart.dispatchAction({
      type: 'showTip',
      seriesIndex: 0,
      dataIndex: -1
    });
  }
}
</script>

<template>
  <div class="tasks-overview-chart">
    <span class="tasks-overview-chart__title">
      {{$t('managerLounge.totalNumberOfTasks')}} {{tasksStatistics.TOTAL}}
    </span>
    <vue-echarts :option="polar" ref="chart" />
  </div>
</template>

<style lang="scss">
.tasks-overview-chart {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 14px;
  margin-bottom: 37px;

  &__title {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
    color: #424242
  }

  .vue-echarts {
    width: 100%;
    height: 400px;
  }
}
</style>