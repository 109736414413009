<script>
import moment from 'moment-timezone';
export default {
  name: "ManagerJobsListItem",
  props: {
    itemData: {
      type: Object
    }
  },
  data: function () {
    return {
      modifiedData: null,
    }
  },
  watch: {
    'modifiedData': {
      handler(newVal, oldVal) {
        if (oldVal) {
          this.$emit('job-changed', this.modifiedData)
        }
      },
      deep: true
    },
  },
  computed: {
    updatedDateTimeFormattedDate() {
      var date = this.itemData.updatedDateTime.substring(0, this.itemData.updatedDateTime.indexOf("["));
      var timeZone = this.itemData.updatedDateTime.replace( /(^.*\[|\].*$)/g, '' );

      return moment(date).tz(timeZone).format('DD/MMM/YYYY h:mm A');
    }
  },
  mounted: function () {
    this.modifiedData = this.itemData;
  }
}
</script>

<template>
  <div class="manager-job-item manager-jobs-table__row" v-if="modifiedData">
    <div class="manager-jobs-table__col">
      {{itemData.title}}
    </div>
    <div class="manager-jobs-table__col">
      {{itemData.applied}}
    </div>
    <div class="manager-jobs-table__col">
      <input type="number"
             min="0"
             max="200"
             v-model="modifiedData.opened"
             oninput="validity.valid || (value='');"
             class="jobs-count-field"
      >
    </div>
    <div class="manager-jobs-table__col">
      {{updatedDateTimeFormattedDate}}
    </div>
    <div class="manager-jobs-table__col">
      {{itemData.lastEditor}}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.manager-job-item {
  display: flex;
  &:hover {
    background: #f6efef;
  }

  .jobs-count-field {
    background: #fff;
    display: inline-block;
    font-size: 12px;
    border: 1px solid #ccc;
    height: 28px;
    color: #4f4f4f;
    padding: 0 8px;
    appearance: none;
    width: 164px;
  }
}
</style>