<script>
import {mapActions, mapState} from "pinia";
import {managerStore} from "@/store/managerStore";
import {baseLoaderStore} from "@/store/baseLoaderStore";
import BasePagination from "@/components/BasePagination.vue";
import ManagerListFilter from "@/views/manager/managerView/ManagerTabView/ManagerListFilter.vue";
import ManagerListItem from "@/views/manager/managerView/ManagerTabView/ManagerListItem.vue";
import BasisForInvoiceModal from "@/views/manager/ManagerBasisForInvoice/BasisForInvoiceModal.vue";
import BaseNoResultMessage from "@/components/BaseNoResultMessage.vue";
export default {
  name: "ManagerList",
  components: {
    BasePagination,
    ManagerListFilter,
    ManagerListItem,
    BasisForInvoiceModal,
    BaseNoResultMessage
  },
  data() {
    return {
      filterParamsTmp: {
        status: 'ACTIVE'
      },
      page: 0,
      perPage: 10,
      totalPages: 1,
    }
  },
  computed: {
    ...mapState(managerStore,[
      'managersList',
      'managersListTotalPages',
      'managerBasisForInvoiceModalState'
    ]),
  },
  methods: {
    ...mapActions(baseLoaderStore, [
      'showBaseLoader',
      'hideBaseLoader'
    ]),
    ...mapActions(managerStore, [
      'getManagersListAction',
      'getTestEnvAction'
    ]),
    getManagersList(filterParams) {
      if (filterParams) {
        this.filterParamsTmp = filterParams;
        this.page = 0;
      }

      this.showBaseLoader();
      this.getManagersListAction(this.page, this.perPage, this.filterParamsTmp).then(()=> {
        this.hideBaseLoader();
      }).catch(()=> {
        this.hideBaseLoader();
      });
    },
    changePage(page) {
      this.page = page;
      this.showBaseLoader();
      this.getManagersListAction(this.page, this.perPage, this.filterParamsTmp).then(()=> {
        this.hideBaseLoader();
      })
    }
  },
  mounted() {
    this.showBaseLoader();
    this.getTestEnvAction().then(()=> {
      this.getManagersListAction(this.page, this.perPage, this.filterParamsTmp).then(()=> {
        this.hideBaseLoader();
      });
    })
  }
}
</script>

<template>
  <div>
    <ManagerListFilter @get-data="getManagersList"/>
    <div class="managers-list">
      <template v-if="managersList.length > 0">
        <manager-list-item v-for="item in managersList"
                           :key="item.userId"
                           :item-data="item"
                           @get-managers-data="getManagersList()"
        />
      </template>
      <template v-else>
        <base-no-result-message :text="$t('common.noResults')"/>
      </template>
    </div>
    <base-pagination @change="changePage"
                     :current-page="page"
                     :max-pages="managersListTotalPages"
    />
    <basis-for-invoice-modal v-if="managerBasisForInvoiceModalState"/>
  </div>
</template>

<style>
.managers-list {
  margin-bottom: 30px;
}
</style>