<script>
import {mapActions, mapState} from "pinia";
import {authStore} from "@/store/authStore";
import {managerStore} from "@/store/managerStore";
import ManagerCompanyInfoForm from "@/views/manager/ManagerCompanyInfoForm.vue";
import ManagerCompanyInfoModal from "@/views/manager/manager-modals/ManagerCompanyInfoModal.vue";

export default {
  name: "ManagerCompanyInfoSection",
  components: {
    ManagerCompanyInfoForm,
    ManagerCompanyInfoModal
  },
  data() {
    return {
      companyInfoModalState: false
    }
  },
  computed: {
    ...mapState(authStore, [
      'managerProfileInfo',
    ]),
  },
  methods: {
    ...mapActions(managerStore, [
      'getManagerProfileInfo',
    ]),
  }
}
</script>

<template>
  <div class="company-info-section">
    <div class="d-flex justify-content-between">
      <span class="company-info-section__title">Company information</span>
      <button type="button" class="go-to-ka-btn mr-10" @click="companyInfoModalState = true">Edit company information</button>
    </div>
    <manager-company-info-form :edit-mode="false"/>
    <manager-company-info-modal :company-info-modal-state="companyInfoModalState"
                                :edit-mode-state="true"
                                @on-close="companyInfoModalState = false"
    />
  </div>
</template>

<style lang="scss">


</style>