<script>
export default {
  name: 'BasePagination',
  props: {
    currentPage: {
      default: 0,
      required: true,
    },
    maxPages: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  computed: {
    pagesCount() {
      return this.maxPages;
    }
  },
  methods: {
    changePage(number) {
      if (number >= 0 && number <= (this.maxPages - 1)) {
        this.$emit('change', number)
      }
    }
  }
}
</script>

<template>
  <ul class="pagination">
    <li class="page-item">
      <a class="page-link"
         @click="changePage(0)"
      >
        {{$t('pagination.first')}}
      </a>
    </li>
    <li class="page-item">
      <a class="page-link"
         @click="changePage(currentPage - 1)"
      >
        <font-awesome-icon :icon="['fas', 'chevron-left']"/>
      </a>
    </li>
    <li v-for="i in pagesCount"
        :key="i"
        :class="{'active': currentPage === (i - 1)}"
        class="page-item"
    >
      <a class="page-link"
         @click="changePage(i - 1)"
      >
        {{i}}
      </a>
    </li>
    <li class="page-item">
      <a class="page-link"
         @click="changePage(currentPage + 1)"
      >
        <font-awesome-icon :icon="['fas', 'chevron-right']"/>
      </a>
    </li>
    <li class="page-item">
      <a class="page-link"
         @click="changePage(pagesCount - 1)"
      >
        {{$t('pagination.last')}}
      </a>
    </li>
  </ul>
</template>

<style lang="scss">
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: .25rem;

  .page-item{
    display: inline;

    &:first-child{
      .page-link{
        margin-left: 0;
        border-bottom-left-radius: .25rem;
        border-top-left-radius: .25rem
      }
    }

    &:last-child{
      .page-link{
        border-bottom-right-radius: .25rem;
        border-top-right-radius: .25rem;
      }
    }
  }

  .page-item.active .page-link,.page-item.active .page-link:focus,.page-item.active .page-link:hover{z-index:2;color:#fff;cursor:default;background-color:#7835a6;border-color:#7835a6}
  .page-item.disabled .page-link,.page-item.disabled .page-link:focus,.page-item.disabled .page-link:hover{color:#818a91;pointer-events:none;cursor:not-allowed;background-color:#fff;border-color:#ddd}
  .page-link{position:relative;float:left;padding:.5rem .75rem;margin-left:-1px;color:#7835a6;text-decoration:none;background-color:#fff;border:1px solid #ddd}
  .page-link:focus,.page-link:hover{background-color:#eceeef;border-color:#ddd}
  .pagination-lg .page-link{padding:.75rem 1.5rem;font-size:1.25rem}
  .pagination-lg .page-item:first-child .page-link{border-bottom-left-radius:.3rem;border-top-left-radius:.3rem}
  .pagination-lg .page-item:last-child .page-link{border-bottom-right-radius:.3rem;border-top-right-radius:.3rem}
  .pagination-sm .page-link{padding:.275rem .75rem;font-size:.875rem}
  .pagination-sm .page-item:first-child .page-link{border-bottom-left-radius:.2rem;border-top-left-radius:.2rem}
  .pagination-sm .page-item:last-child .page-link{border-bottom-right-radius:.2rem;border-top-right-radius:.2rem}
}
</style>