import {Attachment} from "@/store/model/Attachment";

export class CmsDocumentContentType {

    static VIDEO = "VIDEO";
    static PDF = "PDF";
    static EXCEL = "EXCEL";
    static DOCX = "DOCX";
    static PRESENTATION = "PRESENTATION";
    static HTML_TEXT = "HTML_TEXT";
}

export class CmsDocumentContentStatus {

    static DRAFT = "DRAFT";
    static PUBLISHED = "PUBLISHED";
    static UNPUBLISHED = "UNPUBLISHED";
    static PROCESSING = "PROCESSING";
    static FAILED = "FAILED";
}

export class Content {

    constructor(data) {
        this.type = data.type; // CmsDocumentContentType
        this.status = data.status; // CmsDocumentContentStatus
        this.contentId = data.contentId;
        this.contentVersion = data.contentVersion;
        this.title = data.title;
        this.contentNumber = data.contentNumber;
        this.attachments = data.attachments.map(a => new Attachment(a));
    }
}