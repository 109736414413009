import { defineStore } from 'pinia';
import axios from 'axios';
export const documentStore = defineStore({
    id: 'documentStore',
    state: () => ({

    }),
    actions: {
        createAttachmentDocument(file, type, title, contentNumber) {
            const bodyFormData = new FormData();
            bodyFormData.append('qqfile',  new Blob(file), file[0].name);
            bodyFormData.append('title',  title);
            bodyFormData.append('type',  type);
            const url = contentNumber ?
                `/api/cms/attachment?contentNumber=${contentNumber}&type=${type}&title=${title}` :
                `/api/cms/attachment?type=${type}&title=${title}`;
            return axios.post(url, bodyFormData, {
                headers: { "Content-Type": "multipart/form-data" }
            });
        },
        updateAttachmentDocument(contentId, attachmentId, file) {
            const bodyFormData = new FormData();
            bodyFormData.append('qqfile',  new Blob(file), file[0].name);
            return axios.put(`/api/cms/${contentId}/attachment/${attachmentId}`, bodyFormData, {
                headers: { "Content-Type": "multipart/form-data" }
            });
        },
        createTextDocument(data, contentNumber) {
            const url = contentNumber ?
                `/api/cms/text?contentNumber=${contentNumber}` :
                `/api/cms/text`;
            return axios.post(url, data);
        },
        updateTextDocument(contentId, data) {
            return axios.put(`/api/cms/${contentId}/text`, data);
        },
        downloadAttachment(attachmentsHash) {
            return axios.get(`/api/attachment/${attachmentsHash}/download`, { responseType: 'blob' });
        },
        getDocumentContent(documentId) {
            return axios.get(`/api/cms/${documentId}`);
        },
        getTextDocumentContent(documentId) {
            return axios.get(`/api/cms/${documentId}/text`);
        }
    }
})